import { useQuery } from "@apollo/client";
import React, { useContext, useEffect, useState } from "react";
import { useUserContext } from "../../utils/hooks/contexts/userContext";

import { EmpresaAdmin } from "./EmpresaAdmin";
import { EmpresaNoAdmin } from "./EmpresaNoAdmin";
import {
  QUERY_GETINFO_CLIENTE,
  QUERY_OBTENER_ROL_USUARIO,
} from "./Query/Querys-Mutations";

export interface empresa {
  [key: string]: number | string;
}

const empresa_vacia: empresa = {
  fechaRegistro: "",
  nombre: "",
  canton: "",
  provincia: "",
  descripcion: "",
  productos: "",
  antiProductos: "",
  keywords: "",
  cedula: "",
};

export const Empresa = () => {
  const { User } = useUserContext();
  const [datosActuales, setDatosActuales] = useState(empresa_vacia);
  const [rolUsuario, setRolUsuario] = useState(false);

  const QueryCliente = useQuery(QUERY_GETINFO_CLIENTE, {
    variables: {
      emailUser: User.email,
    },
  });

  const QueryRolCliente = useQuery(QUERY_OBTENER_ROL_USUARIO, {
    variables: {
      emailUsuario: User.email,
    },
  });

  useEffect(() => {
    if (QueryCliente.data) {
      const cliente = (QueryCliente.data.cliente as empresa) ?? empresa_vacia;

      setDatosActuales(cliente);

      QueryCliente.refetch();
    }
  }, [QueryCliente.data]);

  useEffect(() => {
    if (QueryRolCliente.data) {
      const rol = QueryRolCliente.data.obternerRolUsuario.mensaje ?? "";

      if (rol === "EsUsuarioAdministrador") setRolUsuario(true);
      if (rol === "EsUsuarioNormal") setRolUsuario(false);
    }
  }, [QueryRolCliente.data]);

  if (rolUsuario) {
    return <EmpresaAdmin datosActuales={datosActuales} />;
  } else {
    return <EmpresaNoAdmin datosActuales={datosActuales} />;
  }
};

import React, { useState } from "react";

import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import * as Styles from "./SearchBarStyles";
import { Divider, InputBase, Paper } from "@mui/material";

export default function CustomizedInputBase(props: {
  buscar: (palabras: string) => Promise<void>;
}) {
  const [palabras, setPalabras] = useState("");

  return (
    <Paper sx={Styles.root}>
      <IconButton
        sx={Styles.iconButton}
        aria-label="busqueda"
        onClick={() => props.buscar(palabras)}
      >
        <SearchIcon />
      </IconButton>
      <Divider sx={Styles.divider} orientation="vertical" />
      <InputBase
        sx={Styles.input}
        placeholder="Ingrese el número de procedimiento o palabras de búsqueda"
        inputProps={{ "aria-label": "busqueda" }}
        onChange={(e) => setPalabras(e.target.value)}
      />
    </Paper>
  );
}

import React, { useState, useContext } from "react";
import clsx from "clsx";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  mainListItems,
  secondaryListItems,
  HelpListItems,
} from "../ListItems/ListItems";
import * as Styles from "./Styles";
import { SessionContext } from "../../utils/hooks/contexts/SessionContext";
import {
  Container,
  Divider,
  Drawer,
  IconButton,
  List,
  Typography,
} from "@mui/material";

interface MenuProps {
  signOut: () => void;
}

export default function Menu() {
  const [session] = useContext(SessionContext);
  const [open, setOpen] = useState(window.screen.width >= 1600 ? true : false);
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  return (
    <Container>
      <Drawer
        variant="permanent"
        sx={{
          paper: clsx(Styles.drawerPaper, !open && Styles.drawerPaperClose),
        }}
        open={open}
      >
        <Container
          sx={Styles.nombre}
          disableGutters={true}
          style={{ marginTop: "65px" }}
        >
          <IconButton onClick={handleDrawerOpen}>
            {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
          <Typography
            variant="h6"
            style={{ display: open ? "inherit" : "none", fontSize: "1.1rem" }}
          ></Typography>
        </Container>
        <Divider />
        <List>{mainListItems}</List>
        <Divider />
        <List>{secondaryListItems}</List>
        <Divider />
        <List>{HelpListItems(null)}</List>
      </Drawer>
    </Container>
  );
}

import { createContext, useContext, useState } from "react";

interface Props {
  children: JSX.Element | JSX.Element[];
}

export interface User {
  email: string | null;
  username: string | null;
  token: string | null;
}

export type UserContextTytpe = {
  User: User;
  LoginUser: (user: User) => void;
  IsLooged: boolean;
  SignoutUser: () => void;
};

const UserContext = createContext<UserContextTytpe>({
  User: { email: "", username: "", token: "" },
  LoginUser: ({ email = "", username = "" }) => {},
  IsLooged: false,
  SignoutUser: () => {},
});

export const useUserContext = () => {
  return useContext(UserContext);
};

export const UserProvider = ({ children }: Props) => {
  const [user, setUser] = useState<User>({
    email: "",
    username: "",
    token: "",
  });

  const [logeado, setLogeado] = useState(false);

  const loginUser = (user: User) => {
    setUser(user);
    setLogeado(true);
  };

  const signoutUser = () => {
    localStorage.removeItem("token");
    setLogeado(false);
  };

  return (
    <UserContext.Provider
      value={{
        User: user,
        LoginUser: loginUser,
        IsLooged: logeado,
        SignoutUser: signoutUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

import { createTheme } from "@mui/material";

const theme = createTheme();
export const root = {
  display: "flex",
  flexGrow: 1,
  justifyContent: "center",
};
export const content = {
  flexGrow: 1,
  height: "100%",
  overflow: "auto",
};
export const container = {
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(4),
};
export const paper = {
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  width: "100%",
  backgroundColor: "fbfef9",
};
export const titulo = {
  fontFamily: `'Comfortaa', cursive`,
  margin: 2,
  zIndex: 10,
};
export const usuario = {
  width: "100%",
};
export const etiqueta = {
  fontSize: 20,
  fontFamily: `'Comfortaa', cursive`,
};
export const nombre = {
  width: "100%",
};
export const correo = {
  width: "100%",
};
export const boton = {
  justifyContent: "space-around",
  display: "flex",
};

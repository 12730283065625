import { createTheme } from "@mui/material";

const theme = createTheme();

const drawerWidth = 240;

export const drawerPaper = {
  position: "absolute",
  whiteSpace: "nowrap",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  backgroundColor: "#fbfef9",
};
export const drawerPaperClose = {
  overflowX: "hidden",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: theme.spacing(7),
  [theme.breakpoints.up("sm")]: {
    width: theme.spacing(9),
  },
};
export const nombre = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "left",
  alignItems: "center",
  marginLeft: "0.3rem",
};

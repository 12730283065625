import { ApolloLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

export const errorMiddleware = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      const { extensions } = error;
      if (extensions && extensions.response) {
        const error = extensions.response as any;
        const statusCode = error.statusCode;

        if (statusCode === 401) {
          window.location.href = "/login";
        }
      }
    });
  }
});

export const authMiddleware = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem("token");

  operation.setContext(({ headers }: any) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
    },
  }));

  return forward(operation);
});

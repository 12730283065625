import React from "react";

import DashboardIcon from "@mui/icons-material/Dashboard";
import BusinessIcon from "@mui/icons-material/Business";
import PeopleIcon from "@mui/icons-material/People";

import StorageIcon from "@mui/icons-material/Storage";

import PersonIcon from "@mui/icons-material/Person";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Link } from "react-router-dom";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useUserContext } from "../../utils/hooks/contexts/userContext";

let link = {
  color: "rgba(0,0,0,0.87)",
  textDecoration: "none",
};

export const mainListItems = (
  <div>
    <Link to="/usuarios" style={link}>
      <ListItem button>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary="Usuarios" />
      </ListItem>
    </Link>
    <Link to="/empresa" style={link}>
      <ListItem button>
        <ListItemIcon>
          <BusinessIcon />
        </ListItemIcon>
        <ListItemText primary="Empresa" />
      </ListItem>
    </Link>
  </div>
);

export const secondaryListItems = (
  <div>
    <Link to="/dashboard" style={link}>
      <ListItem button>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Tablero" />
      </ListItem>
    </Link>
    <Link to="/licitacionesPresentes" style={link}>
      <ListItem button>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Licitaciones de hoy" />
      </ListItem>
    </Link>

    <Link to="/consulta" style={link}>
      <ListItem button>
        <ListItemIcon>
          <StorageIcon />
        </ListItemIcon>
        <ListItemText primary="Consultar datos" />
      </ListItem>
    </Link>
  </div>
);

export const HelpListItems = (e: any) => {
  const { SignoutUser } = useUserContext();
  return (
    <div>
      <Link to="/soporte" style={link}>
        <ListItem button>
          <ListItemIcon>
            <ContactSupportIcon />
          </ListItemIcon>
          <ListItemText primary="Contactar soporte" />
        </ListItem>
      </Link>
      <ListItem
        button
        onClick={() => {
          SignoutUser();
        }}
      >
        <ListItemIcon>
          <ExitToAppIcon />
        </ListItemIcon>
        <ListItemText primary="Cerrar sesión" />
      </ListItem>
    </div>
  );
};

import { gql } from "@apollo/client";

export const MUTATION_CAMBIAR_ESTADO = gql`
  mutation Mutation(
    $emailUser: String
    $cambiarEstado: String
    $numeroProcedimiento: String
  ) {
    cambiarEstado(
      emailUser: $emailUser
      estado: $cambiarEstado
      numeroProcedimiento: $numeroProcedimiento
    ) {
      mensaje
    }
  }
`;

import { Grid, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { RegisterContext } from "../../utils/hooks/contexts/registerContext";
import { RegisterActions } from "../../utils/hooks/contexts/registerReducer";

const root = {
  margin: 0,

  marginLeft: 7,
};
const input = {
  width: "100%",
};

export default function Usuarios() {
  const { state, dispatch } = useContext(RegisterContext);
  const [validezCorreos, setValidezCorreos] = useState<boolean[]>([
    true,
    true,
    true,
  ]);

  const validacionCorreo = (correo: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(correo.toLowerCase()) || correo === "";
  };

  const updateUsersArray = (
    userIndex: number,
    fieldName: string,
    newValue: string
  ) => {
    if (fieldName === "email") {
      let correosArray = validezCorreos;
      correosArray[userIndex] = validacionCorreo(newValue);
      setValidezCorreos(correosArray);
    }
    let userArray = state.users;
    userArray[userIndex] = {
      ...userArray[userIndex],
      [fieldName]: newValue,
    };
    return userArray;
  };

  const handleUserChange = (
    userIndex: number,
    fieldName: string,
    newValue: string
  ) => {
    dispatch({
      type: RegisterActions.SET_REGISTRATION_USERS,
      value: {
        ...state,
        users: updateUsersArray(userIndex, fieldName, newValue),
      },
    });
  };

  const handleEmailChange = (
    userIndex: number,
    fieldName: string,
    newValue: string
  ) => {
    dispatch({
      type: RegisterActions.SET_REGISTRATION_USERS,
      value: {
        ...state,
        users: updateUsersArray(userIndex, fieldName, newValue),
      },
    });
  };

  return (
    <Grid sx={root} container spacing={4}>
      <Grid item xs={10}>
        <Typography color="primary" variant="h4" align="left">
          Usuarios
        </Typography>
        <Typography color="primary" variant="h6" align="left">
          Escriba el nombre y el correo de las otras personas que harán uso del
          servicio:
        </Typography>
      </Grid>

      {state.users.map((_user, index) => (
        <Grid item xs={10} key={index}>
          <Grid container spacing={5}>
            <Grid item xs={5}>
              <Typography color="primary" variant="h6" align="left">
                Nombre
              </Typography>
              <TextField
                onChange={(e) =>
                  handleUserChange(index, "name", e.target.value)
                }
                sx={input}
                value={state.users[index].name}
                label="Nombre"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={7}>
              <Typography color="primary" variant="h6" align="left">
                Correo
              </Typography>
              <TextField
                error={validezCorreos[index] ? false : true}
                onChange={(e) =>
                  handleEmailChange(index, "email", e.target.value)
                }
                sx={input}
                value={state.users[index].email}
                label="Correo"
                variant="outlined"
                helperText={
                  validezCorreos[index]
                    ? null
                    : "Escriba una dirección de correo válida"
                }
              />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
}

import { createTheme } from "@mui/material";

const drawerWidth = 240;

const theme = createTheme();
export const root = {
  display: "flex",
  width: "100%",
};

export const drawerPaper = {
  position: "relative",
  whiteSpace: "nowrap",
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
};
export const drawerPaperClose = {
  overflowX: "hidden",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: theme.spacing(7),
  [theme.breakpoints.up("sm")]: {
    width: theme.spacing(9),
  },
};

export const content = {
  flexGrow: 1,
  height: "100%",
  overflow: "auto",
};
export const container = {
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
};
export const paper = {
  margin: theme.spacing(3),
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  width: "fit-container",
};

export const fixedHeight = {
  height: 260,
};

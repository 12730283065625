import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../utils/hooks/contexts/userContext";

export const ProtectedRoute = ({ children }: any) => {
  const { IsLooged } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!IsLooged) navigate("/login");
  }, [IsLooged]);

  return children;
};

import React, { useEffect, useMemo, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Cell,
  ResponsiveContainer,
} from "recharts";
import { licitacion } from "./Tablero";
import { Typography, Box } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GET_METRICAS } from "./Query/QueryMetricas";
import { useUserContext } from "../../utils/hooks/contexts/userContext";

const box = {
  height: "80%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};

interface dato {
  name: string;
  cantidad: number;
}

interface Props {
  refrescar: boolean;
}

const colors = ["#0e79b2", "#6beb67", "#ff6b35", "#340046"];

export default function Chart(props: Props) {
  const { User } = useUserContext();
  const [listChar, setListChar] = useState(new Array<dato>());
  const { data, error, refetch } = useQuery(GET_METRICAS, {
    variables: {
      emailUsuario: User.email,
    },
  });

  useEffect(() => {
    if (data) {
      setListChar([
        {
          name: "En revisión",
          cantidad: data.metricas.contadorEstadoLicitaciones0 ?? 0,
        },
        {
          name: "Preparando",
          cantidad: data.metricas.contadorEstadoLicitaciones1 ?? 0,
        },
        {
          name: "Esperando",
          cantidad: data.metricas.contadorEstadoLicitaciones2 ?? 0,
        },
        {
          name: "Adjudicada",
          cantidad: data.metricas.contadorEstadoLicitaciones3 ?? 0,
        },
      ]);
      refetch();
    }
  }, [data, props.refrescar]);

  if (data) {
    return (
      <React.Fragment>
        <ResponsiveContainer width="100%" height="80%">
          <BarChart
            data={listChar}
            margin={{
              top: 16,
              right: 16,
              bottom: 0,
              left: 0,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis allowDecimals={false} />
            <Bar dataKey="cantidad">
              {listChar.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Box sx={box}>
          <Typography>No hay datos todavía.</Typography>
        </Box>
      </React.Fragment>
    );
  }
}

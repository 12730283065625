import { createTheme, Grid, Typography } from "@mui/material";

const theme = createTheme();

const root = {
  margin: 0,
  marginLeft: 3,
  marginRight: 6,
  width: "auto",
};
const item = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  marginLeft: theme.spacing(1),
};
const icono = {
  fontSize: 20,
};
const subtitulo = {
  fontWeight: 600,
  marginRight: theme.spacing(1),
};
const enfasis = {
  fontWeight: 600,
};

export default function TerminosYCondiciones() {
  return (
    <Grid sx={root} container spacing={4} direction="column">
      <Grid item>
        <Typography color="primary" variant="h4" align="left">
          Términos y condiciones
        </Typography>
      </Grid>
      <Grid sx={item} item direction="row" container xs={12}>
        <Typography variant="body1" align="justify">
          <Typography
            component="span"
            sx={subtitulo}
            color="primary"
            variant="body1"
          >
            Producto:
          </Typography>
          <span style={enfasis}>Licitanauta CR</span> es una{" "}
          <span style={enfasis}>aplicación web</span> que le permite gestionar
          sus procesos de licitación pública mediante el acceso a una plataforma
          integrada para el fácil manejo de información valiosa.
          <span style={enfasis}>
            {" "}
            Licitanauta CR no se encuentra conectada a SICOP y el actual proceso
            de lictación debe llevarse debidamente en la plataforma de SICOP
            proveída por el gobierno.
          </span>
        </Typography>
      </Grid>
      <Grid sx={item} item direction="row" container xs={12}>
        <Typography variant="body1" align="justify">
          <Typography
            component="span"
            sx={subtitulo}
            color="primary"
            variant="body1"
          >
            Servicio de notificaciones:
          </Typography>
          Licitanauta CR se encuentra enlazado con el Sistema Integrado de
          COmpras Públicas (SICOP), lo que nos permite enviarle a usted
          <span style={enfasis}> diariamente</span> una lista de licitaciones
          que el sistema considera de su interés y brindarle una plataforma para
          que las busque fácilmente.
        </Typography>
      </Grid>
      <Grid sx={item} item direction="row" container xs={12}>
        <Typography variant="body1" align="justify">
          <Typography
            component="span"
            sx={subtitulo}
            color="primary"
            variant="body1"
          >
            Política de privacidad:
          </Typography>
          Los datos utilizados en la plataforma son proporcionados por el
          Gobierno de Costa Rica y son de carácter públicos. Por otro lado, los
          datos personales proveídos por el usuario en el formulario de registro
          Licitanauta CR{" "}
          <span style={enfasis}>
            no serán proporcionados a ninguna empresa o institución.
          </span>
        </Typography>
      </Grid>
      <Grid sx={item} item direction="row" container xs={12}>
        <Typography variant="body1" align="justify">
          <Typography
            component="span"
            sx={subtitulo}
            color="primary"
            variant="body1"
          >
            Costo del producto:
          </Typography>
          El costo del producto es de $25 al mes, sin embargo, Licitanauta CR le
          ofrece <span style={enfasis}>la primera semana gratis</span> sin
          necesidad de incluir medios de pago.
        </Typography>
      </Grid>
      <Grid sx={item} item direction="row" container xs={12}>
        <Typography variant="body1" align="justify">
          <Typography
            component="span"
            sx={subtitulo}
            color="primary"
            variant="body1"
          >
            Política de entrega:
          </Typography>
          Licitanauta CR{" "}
          <span style={enfasis}>le enviará un correo con la contraseña</span>{" "}
          para su correo electrónico una vez terminado el formulario de registro
          para que puede utilizar la aplicación inmediatamente. Una vez
          terminado el período de prueba, el acceso a la aplicación será
          revocado hasta que se cancele el costo del producto.
        </Typography>
      </Grid>
      <Grid sx={item} item direction="row" container xs={12}>
        <Typography variant="body1" align="justify">
          <Typography
            component="span"
            sx={subtitulo}
            color="primary"
            variant="body1"
          >
            Política de reembolso:
          </Typography>
          Si una vez acabado el período de prueba de la aplicación usted no
          desea continuar con el producto, no será necesario que realice ninguna
          acción adicional. Si decidió cancelar el costo del producto y
          continuar con el uso de la aplicación, este no será reembolsado por el
          del período de activación del primer mes. Terminado el primer mes,
          usted{" "}
          <span style={enfasis}>
            deberá enviar un correo durante los primeros siete días del mes al
            correo info@licitanautacr.com{" "}
          </span>{" "}
          para solicitar un reembolso junto con la cancelación del servicio.
        </Typography>
      </Grid>
    </Grid>
  );
}

import React, { useContext, useState, useEffect } from "react";

import { empresa } from "./Empresa";
import { Container, Grid, Paper, TextField, Typography } from "@mui/material";
import * as Styles from "./Styles";

const inputValues: { [key: string]: { title: string } } = {
  nombre: {
    title: "Nombre de la empresa",
  },
  canton: {
    title: "Cantón",
  },
  provincia: {
    title: "Provincia",
  },
  descripcion: {
    title: "Descripción de la empresa",
  },
  productos: {
    title: "Productos",
  },
  antiProductos: {
    title: "Antiproductos",
  },
  keywords: {
    title: "Palabras clave",
  },
};

interface PropsEmpresa {
  datosActuales: empresa;
}

const fechaRegistroFormateada = (fecha: Date) => {
  return `${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()}`;
};

export const EmpresaNoAdmin = (props: PropsEmpresa) => {
  const fecha = props.datosActuales.fechaRegistro as string;

  const [confirmacion, setConfirmacion] = useState(false);
  const fechaRegistro = new Date(parseInt(fecha));
  const [datosModificados, setDatosModificados] = useState<empresa>(
    props.datosActuales
  );
  useEffect(() => {
    setDatosModificados(props.datosActuales);
  }, [props.datosActuales]);

  const handleDataChange = (key: string, value: string) => {
    setDatosModificados({ ...datosModificados, [key]: value });
  };

  return (
    <Container maxWidth="xl" sx={Styles.container}>
      <Paper sx={Styles.paper}>
        <Grid sx={Styles.root} container spacing={4}>
          <Grid item xs={12}>
            <Typography
              sx={Styles.titulo}
              color="primary"
              variant="h4"
              align="left"
            >
              Datos Empresariales
            </Typography>
            <Typography color="primary" variant="h6" align="center">
              Solo el administrador de la empresa puede hacer cambios a los
              datos de la empresa.
            </Typography>
          </Grid>
          <Grid item xs={6} key={"cedula"}>
            <Typography color="primary" variant="h6" align="center">
              Número de cédula
            </Typography>
            <Typography variant="h6" align="center">
              {props.datosActuales.cedula}
            </Typography>
          </Grid>
          <Grid item xs={6} key={"fechaRegistro"}>
            <Typography color="primary" variant="h6" align="center">
              Fecha de Registro
            </Typography>
            <Typography variant="h6" align="center">
              {fechaRegistroFormateada(fechaRegistro)}
            </Typography>
          </Grid>

          {Object.keys(inputValues).map((key) => (
            <Grid item xs={11} key={key}>
              <Typography color="primary" variant="h6" align="left">
                {inputValues[key].title}
              </Typography>
              <TextField
                disabled={true}
                multiline={
                  ["descripcion", "productos"].includes(key) ? true : false
                }
                rows={["descripcion", "productos"].includes(key) ? 4 : 1}
                onChange={(e) => handleDataChange(key, e.target.value)}
                sx={Styles.input}
                variant="outlined"
                value={datosModificados[key]}
              />
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
};

import { Backdrop, Box, Link, Typography } from "@mui/material";

import Header from "../Header/Header";
import Menu from "../Menu/Menu";
import * as Styles from "./DashboardStyles";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link
        color="inherit"
        href="https://www.licitanautacr.com/"
        sx={{ textDecoration: "none" }}
      >
        Licitanauta CR
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

var clientes: any[] = [];

export const Dashboard = ({ children }: any) => {
  return (
    <>
      <Header clientes={clientes} />

      <div
        className={JSON.stringify(Styles.contenido)}
        style={{ marginLeft: "230px" }}
      >
        <Menu />

        {children}
      </div>
      <Box pt={4}>
        <Copyright />
      </Box>
    </>
  );
};

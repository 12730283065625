import { gql } from "@apollo/client";

export const MUTATION_MODIFICAR_USUARIOS = gql`
  mutation Mutation(
    $emailUsuario: String
    $informacionUsuario1: String
    $informacionUsuario2: String
    $informacionUsuario3: String
    $informacionUsuarioPrincipal: String
  ) {
    modificarUsuarios(
      emailUsuario: $emailUsuario
      informacion: {
        informacionUsuario1: $informacionUsuario1
        informacionUsuario2: $informacionUsuario2
        informacionUsuario3: $informacionUsuario3
        informacionUsuarioPrincipal: $informacionUsuarioPrincipal
      }
    ) {
      mensaje
    }
  }
`;

import { Container, Typography } from "@mui/material";
import React from "react";

import Title from "./Title";

const container = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  flexGrow: 2,
};

interface PropsCantidad {
  cantidad: string;
}

export default function CantidadDestacadas(props: PropsCantidad) {
  return (
    <Container disableGutters={true} sx={container}>
      <Title>Licitaciones destacadas</Title>
      <Typography align="center" component="p" variant="h4">
        {props.cantidad}
      </Typography>
    </Container>
  );
}

import { gql } from "@apollo/client";

export const MUTATION_DESTACAR_LICITACION = gql`
  mutation Mutation($emailUsuario: String, $numeroProcedimiento: String) {
    guardarLicitacionDestacada(
      emailUsuario: $emailUsuario
      numeroProcedimiento: $numeroProcedimiento
    ) {
      mensaje
    }
  }
`;

import React from "react";

import Title from "./Title";
import DeleteIcon from "@mui/icons-material/Delete";

import { licitacion } from "./Tablero";
import {
  createTheme,
  IconButton,
  Link,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
const theme = createTheme();
const tableHeader = {
  color: "#340046",
};
const cell = {
  padding: "6px 12px 6px 12px",
};
const icono = {
  padding: "6px 0px",
};

interface PropsDestacadas {
  licitaciones: licitacion[];
  handleChange: (
    event: React.ChangeEvent<{ value: unknown }>,
    numeroProcedimiento: string
  ) => void;
  borrarLicitacion: (numeroProcedimiento: string) => void;
  cambiarEstadoLicitacion: (
    numeroProcedimiento: string,
    estado: string
  ) => void;
}

let estado = [
  "En revisión",
  "Preparando oferta",
  "Esperando respuesta",
  "Adjudicada",
  "No adjudicada",
];

function link(numeroCartel: string) {
  return `https://www.sicop.go.cr/moduloPcont/pcont/ctract/es/CE_CEJ_ESQ002.jsp?cartelNo=${numeroCartel}&cartelSeq=00`;
}

export default function Destacadas(props: PropsDestacadas) {
  return (
    <React.Fragment>
      <Title>Licitaciones Destacadas</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell sx={{ ...tableHeader, ...cell }}>
              Número Procedimiento
            </TableCell>
            <TableCell sx={{ ...tableHeader, ...cell }}>Institución</TableCell>
            <TableCell sx={{ ...tableHeader, ...cell }}>Descripción</TableCell>
            <TableCell sx={{ ...tableHeader, ...cell }}>Presupuesto</TableCell>
            <TableCell sx={{ ...tableHeader, ...cell }}>Fecha Cierre</TableCell>
            <TableCell sx={{ ...tableHeader, ...cell }}>
              Garantía Participación
            </TableCell>
            <TableCell sx={{ ...tableHeader, ...cell }}>Estado</TableCell>
            <TableCell sx={{ ...tableHeader, ...cell }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.licitaciones.map((licitacion: licitacion, index: number) => (
            <TableRow key={licitacion.numeroProcedimiento} hover={true}>
              <TableCell sx={cell}>
                <Link
                  target="_blank"
                  href={link(licitacion.numeroCartel)}
                  color="secondary"
                >
                  {licitacion.numeroProcedimiento}
                </Link>
              </TableCell>
              <TableCell sx={cell}>{licitacion.institucion}</TableCell>
              <TableCell sx={cell} style={{ minWidth: 250 }}>
                {licitacion.descripcion}
              </TableCell>
              <TableCell sx={cell}>{licitacion.presupuesto}</TableCell>
              <TableCell sx={cell}>{licitacion.fechaCierreRecepcion}</TableCell>
              <TableCell sx={cell}>
                {licitacion.garantiaParticipacion}
              </TableCell>
              <TableCell sx={cell}>
                <Select
                  defaultValue={licitacion.estado}
                  onChange={(e: any) => {
                    props.handleChange(e, licitacion.numeroProcedimiento);

                    props.cambiarEstadoLicitacion(
                      licitacion.numeroProcedimiento,
                      e.target.value
                    );
                  }}
                  variant="outlined"
                >
                  <MenuItem value={0}>{estado[0]}</MenuItem>
                  <MenuItem value={1}>{estado[1]}</MenuItem>
                  <MenuItem value={2}>{estado[2]}</MenuItem>
                  <MenuItem value={3}>{estado[3]}</MenuItem>
                  <MenuItem value={4}>{estado[4]}</MenuItem>
                </Select>
              </TableCell>
              <TableCell sx={icono}>
                <IconButton
                  color="primary"
                  onClick={() =>
                    props.borrarLicitacion(licitacion.numeroProcedimiento)
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";
import React, { useState, useContext, useEffect } from "react";

import Presentes from "./Presentes";

import Title from "./Title";
import * as Styles from "./Styles";
import { useMutation, useQuery } from "@apollo/client";
import { SEND_LICIT_PRESENTES } from "./Query/QueryLicitPresentes";
import { licitacion } from "../Tablero/Tablero";
import { MUTATION_DESTACAR_LICITACION } from "./Query/MutationDestacarLicitacion";
import { useUserContext } from "../../utils/hooks/contexts/userContext";

export const LicitacionesPresentes = () => {
  const { User } = useUserContext();
  const [page, setPage] = React.useState(0);
  const [countItems, setCountItems] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [page2, setPage2] = React.useState(0);
  const [countItems2, setCountItems2] = React.useState(0);
  const [rowsPerPage2, setRowsPerPage2] = React.useState(10);

  const [licitacionDestacada, setLicitacionDestacada] = useState(false);
  const [existelicitacionDestacada, setExisteLicitacionDestacada] =
    useState(false);

  const QueryNuevas = useQuery(SEND_LICIT_PRESENTES, {
    variables: {
      pageSize: rowsPerPage.toString(),
      pageIndex: (page + 1).toString(),
      buscarPorFecha: "Nuevos",
      emailUsuario: User.email,
    },
  });

  const QueryActivas = useQuery(SEND_LICIT_PRESENTES, {
    variables: {
      pageSize: rowsPerPage2.toString(),
      pageIndex: (page2 + 1).toString(),
      buscarPorFecha: "Abiertas",
      emailUsuario: User.email,
    },
  });

  const [licitacionesNuevas, setLicitacionesNuevas] = useState(
    new Array<licitacion>()
  );

  const [licitacionesAbiertas, setLicitacionesAbiertas] = useState(
    new Array<licitacion>()
  );

  useEffect(() => {
    let licitaciones: licitacion[] = [];

    if (QueryNuevas.data) {
      const licit =
        (QueryNuevas.data.licitacionesFechas.items as licitacion[]) ?? [];

      licit.forEach((licitacion: licitacion) => {
        let fecha = new Date(parseInt(licitacion.fechaCierreRecepcion));
        let fechaFormateada = `${fecha.getDate()}/${
          fecha.getMonth() + 1
        }/${fecha.getFullYear()}`;
        if (!licitacion.fechaCierreRecepcion) fechaFormateada = "";
        licitaciones.push({
          numeroCartel: licitacion.numeroCartel,
          numeroProcedimiento: licitacion.numeroProcedimiento,
          institucion: licitacion.institucion,
          descripcion: licitacion.descripcion,
          presupuesto: licitacion.presupuesto,
          fechaCierreRecepcion: fechaFormateada,
          garantiaParticipacion: licitacion.garantiaParticipacion,
          estado: 0,
        });
      });

      setCountItems(parseInt(QueryNuevas.data.licitacionesFechas.countItems));
      setLicitacionesNuevas(licitaciones);

      //   QueryNuevas.refetch();
    }
  }, [QueryNuevas.data]);

  useEffect(() => {
    let licitaciones: licitacion[] = [];

    if (QueryActivas.data) {
      const licit =
        (QueryActivas.data.licitacionesFechas.items as licitacion[]) ?? [];

      licit.forEach((licitacion: licitacion) => {
        let fecha = new Date(parseInt(licitacion.fechaCierreRecepcion));
        let fechaFormateada = `${fecha.getDate()}/${
          fecha.getMonth() + 1
        }/${fecha.getFullYear()}`;
        if (!licitacion.fechaCierreRecepcion) fechaFormateada = "";
        licitaciones.push({
          numeroCartel: licitacion.numeroCartel,
          numeroProcedimiento: licitacion.numeroProcedimiento,
          institucion: licitacion.institucion,
          descripcion: licitacion.descripcion,
          presupuesto: licitacion.presupuesto,
          fechaCierreRecepcion: fechaFormateada,
          garantiaParticipacion: licitacion.garantiaParticipacion,
          estado: 0,
        });
      });

      setCountItems2(parseInt(QueryActivas.data.licitacionesFechas.countItems));
      setLicitacionesAbiertas(licitaciones);

      //   QueryActivas.refetch();
    }
  }, [QueryActivas.data]);

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeRowsPerPage2 = (event: any) => {
    setRowsPerPage2(parseInt(event.target.value, 10));
    setPage2(0);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangePage2 = (event: any, newPage: number) => {
    setPage2(newPage);
  };

  const [destacarLicit, { data }] = useMutation(MUTATION_DESTACAR_LICITACION);

  useEffect(() => {
    if (data) {
      const estado = (data.guardarLicitacionDestacada.mensaje as string) ?? "";

      if (estado === "Exito") setLicitacionDestacada(true);

      if (estado === "yaExiste") setExisteLicitacionDestacada(true);
    }
  }, [data]);

  const destacar = async (numeroProcedimiento: string) => {
    await destacarLicit({
      variables: {
        emailUsuario: User.email,
        numeroProcedimiento: numeroProcedimiento,
      },
    });
  };

  return (
    <Container maxWidth={false} sx={Styles.container}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {licitacionesNuevas.length > 0 ? (
            <Paper sx={Styles.paper}>
              <Title>Licitaciones Nuevas</Title>
              <Box alignItems="center" display="flex" flexDirection="column">
                <Presentes
                  licitaciones={licitacionesNuevas}
                  destacar={destacar}
                />

                <TablePagination
                  component="div"
                  count={countItems}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Box>
            </Paper>
          ) : (
            <Paper sx={Styles.paper}>
              <Title>Licitaciones Nuevas</Title>
              <Typography variant="h6" align="center" gutterBottom={true}>
                No encontramos licitaciones nuevas relacionadas a la actividad
                de su empresa.
              </Typography>
            </Paper>
          )}
          {licitacionesAbiertas.length > 0 ? (
            <Paper sx={Styles.paper}>
              <Title>Licitaciones Abiertas</Title>
              <Box alignItems="center" display="flex" flexDirection="column">
                <Presentes
                  licitaciones={licitacionesAbiertas}
                  destacar={destacar}
                />

                <TablePagination
                  component="div"
                  count={countItems2}
                  page={page2}
                  onPageChange={handleChangePage2}
                  rowsPerPage={rowsPerPage2}
                  onRowsPerPageChange={handleChangeRowsPerPage2}
                />
              </Box>
            </Paper>
          ) : (
            <Paper sx={Styles.paper}>
              <Title>Licitaciones Abiertas</Title>
              <Typography variant="h6" align="center" gutterBottom={true}>
                No encontramos licitaciones abiertas relacionadas a la actividad
                de su empresa.
              </Typography>
            </Paper>
          )}
        </Grid>
      </Grid>

      <Backdrop open={QueryNuevas.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop open={QueryActivas.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog open={licitacionDestacada}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            La licitacion se destaco con exito
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setLicitacionDestacada(false)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={existelicitacionDestacada}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            La licitacion ya se encuentra destacada
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setExisteLicitacionDestacada(false)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

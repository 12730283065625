import React, { useContext } from "react";

import astronauta from "../../utils/astronauta_blanco.png";

import * as Styles from "./HeaderStyles";
import { SessionContext } from "../../utils/hooks/contexts/SessionContext";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Box,
  Input,
  MenuItem,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import { useUserContext } from "../../utils/hooks/contexts/userContext";

interface HeaderProps {
  clientes: any[];
}

export default function Header(props: HeaderProps) {
  const { User } = useUserContext();
  let navigate = useNavigate();
  const [session, dispatch] = useContext(SessionContext);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    let index = event.target.value as number;
    dispatch({
      ...session,
      id: props.clientes[index].cedula as string,
      client: props.clientes[index].nombre,
      vigencia: props.clientes[index].vigencia,
    });
    navigate("/");
  };

  return (
    <>
      <AppBar sx={Styles.header} position="fixed">
        <Toolbar sx={Styles.toolBar}>
          <Box sx={Styles.banner}>
            <img src={astronauta} style={Styles.astronauta} alt="banner" />
            <Typography variant="h5" sx={Styles.titulo} color="primary">
              Licitanauta
            </Typography>
          </Box>
          <Box>
            <h3>{User.email}</h3>
          </Box>
        </Toolbar>
      </AppBar>

      <div style={{ marginTop: "80px" }}></div>
    </>
  );
}

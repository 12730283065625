import React, { useState, PropsWithChildren } from "react";
import { Session, initialSession } from "../../../models/Session";

const SessionContext = React.createContext<
  [Session, (session: Session) => void]
>([initialSession, () => {}]);
const urlParams = new URLSearchParams(window.location.search);

const SessionContextProvider = (props: PropsWithChildren<Session>) => {
  let session: Session = {
    signOut: props.signOut,
    id: "",
    client: "",
    vigencia: true,
    user: props.user,
    loading: false,
    admin: false,
    urlParams: urlParams,
  };
  const [sessionState, setSessionState] = useState(session);
  const defaultSessionContext: [Session, typeof setSessionState] = [
    sessionState,
    setSessionState,
  ];

  return (
    <SessionContext.Provider value={defaultSessionContext}>
      {props.children}
    </SessionContext.Provider>
  );
};

export { SessionContextProvider, SessionContext };

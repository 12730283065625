import { gql } from "@apollo/client";

export const mutacionRegistro = gql`
  mutation CrearCliente(
    $cedula: String!
    $nombre: String!
    $provincia: String
    $canton: String
    $descripcion: String
    $productos: String
    $antiProductos: String
    $nombreUsuarioPrincipal: String!
    $correoUsuarioPrincipal: String!
    $passwordUsuarioPrincipal: String!
    $nombre1: String
    $correo1: String
    $password1: String
    $nombre2: String
    $correo2: String
    $password2: String
    $nombre3: String
    $correo3: String
    $password3: String
    $keywords: String
    $fechaRegistro: String
    $fechaAbandono: String
  ) {
    createCliente(
      createClienteInput: {
        cedula: $cedula
        nombre: $nombre
        provincia: $provincia
        canton: $canton
        descripcion: $descripcion
        productos: $productos
        antiProductos: $antiProductos
        nombreUsuarioPrincipal: $nombreUsuarioPrincipal
        correoUsuarioPrincipal: $correoUsuarioPrincipal
        passwordUsuarioPrincipal: $passwordUsuarioPrincipal
        nombre1: $nombre1
        correo1: $correo1
        password1: $password1
        nombre2: $nombre2
        correo2: $correo2
        password2: $password2
        nombre3: $nombre3
        correo3: $correo3
        password3: $password3
        keywords: $keywords
        fechaRegistro: $fechaRegistro
        fechaAbandono: $fechaAbandono
      }
    ) {
      cedula
      nombre
      usuarios {
        correo
        nombre
      }
    }
  }
`;

import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { empresa } from "./Empresa";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import * as Styles from "./Styles";
import { useMutation, useQuery } from "@apollo/client";
import { MUTATION_UPDATE_CLIENTE } from "./Query/Querys-Mutations";
import { useUserContext } from "../../utils/hooks/contexts/userContext";

const inputValues: { [key: string]: { title: string } } = {
  nombre: {
    title: "Nombre de la empresa",
  },
  canton: {
    title: "Cantón",
  },
  provincia: {
    title: "Provincia",
  },
  descripcion: {
    title: "Descripción de la empresa",
  },
  productos: {
    title: "Productos",
  },
  antiProductos: {
    title: "Antiproductos",
  },
  keywords: {
    title: "Palabras clave",
  },
};

interface PropsEmpresa {
  datosActuales: empresa;
}

const fechaRegistroFormateada = (fecha: Date) => {
  return `${fecha.getDate()}/${fecha.getMonth() + 1}/${fecha.getFullYear()}`;
};

export const EmpresaAdmin = (props: PropsEmpresa) => {
  const fecha = props.datosActuales.fechaRegistro as string;

  const { User } = useUserContext();
  const [confirmacion, setConfirmacion] = useState(false);

  const fechaRegistro = new Date(parseInt(fecha));
  const [datosModificados, setDatosModificados] = useState(props.datosActuales);

  const [addTodo, { error, loading }] = useMutation(MUTATION_UPDATE_CLIENTE);

  useEffect(() => {
    setDatosModificados(props.datosActuales);
  }, [props.datosActuales]);

  const handleDataChange = (key: string, value: string) => {
    setDatosModificados({ ...datosModificados, [key]: value });
  };

  const ConfirmarCambios = () => {
    return (
      <Dialog open={confirmacion}>
        <DialogTitle>
          <Typography
            component="span"
            variant="h6"
            style={{ fontFamily: `"Comfortaa", cursive` }}
          >
            Confirmación de cambios
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography component="span" variant="body1">
              Cambios en la descripción de su empresa, productos, antiproductos
              o en las palabras clave pueden afectar los resultados de sus
              correos diarios de licitaciones.
            </Typography>
            <br />
            <Typography component="span" variant="body1">
              ¿Desea continuar con los cambios?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setConfirmacion(false)}
            color="primary"
          >
            No
          </Button>
          <Button variant="contained" onClick={guardarCambios} color="primary">
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const guardarCambios = async () => {
    setConfirmacion(false);
    await addTodo({
      variables: {
        nombre: datosModificados.nombre,
        emailUser: User.email,
        canton: datosModificados.canton,
        provincia: datosModificados.provincia,
        descripcion: datosModificados.descripcion,
        productos: datosModificados.productos,
        antiProductos: datosModificados.antiProductos,
        keywords: datosModificados.keywords,
      },
    });
  };

  return (
    <Container maxWidth="xl" sx={Styles.container}>
      <Paper sx={Styles.paper}>
        <Grid sx={Styles.root} container spacing={4}>
          <Grid item xs={12}>
            <Typography
              sx={Styles.titulo}
              color="primary"
              variant="h4"
              align="left"
            >
              Datos Empresariales
            </Typography>
          </Grid>
          <Grid item xs={6} key={"cedula"}>
            <Typography color="primary" variant="h6" align="center">
              Número de cédula
            </Typography>
            <Typography variant="h6" align="center">
              {props.datosActuales.cedula}
            </Typography>
          </Grid>
          <Grid item xs={6} key={"fechaRegistro"}>
            <Typography color="primary" variant="h6" align="center">
              Fecha de Registro
            </Typography>
            <Typography variant="h6" align="center">
              {fechaRegistroFormateada(fechaRegistro)}
            </Typography>
          </Grid>

          {Object.keys(inputValues).map((key) => (
            <Grid item xs={11} key={key}>
              <Typography color="primary" variant="h6" align="left">
                {inputValues[key].title}
              </Typography>
              <TextField
                multiline={
                  ["descripcion", "productos"].includes(key) ? true : false
                }
                rows={["descripcion", "productos"].includes(key) ? 4 : 1}
                onChange={(e) => handleDataChange(key, e.target.value)}
                sx={Styles.input}
                variant="outlined"
                value={datosModificados[key]}
              />
            </Grid>
          ))}
          <Grid item sm={12} sx={Styles.boton}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setDatosModificados(props.datosActuales)}
            >
              Revertir cambios
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setConfirmacion(true)}
            >
              Guardar cambios
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <ConfirmarCambios />
    </Container>
  );
};

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Empresa } from "../../Modulos/Empresa/Empresa";
import { Login } from "../../Componentes/Login/Login";
import { ProtectedRoute } from "../../Componentes/ProtectedRoute/ProtectedRoute";
import { RestablecerPassword } from "../../Componentes/RestablecerContrasena/RestablecerPassword";
import { Dashboard } from "../../Componentes/Dasboard/Dashboard";
import ForumularioRegistro from "../../Modulos/FormularioRegistro/FormularioRegistro";
import { RegisterContextProvider } from "../hooks/contexts/registerContext";

import Tablero from "../../Modulos/Tablero/Tablero";
import { Soporte } from "../../Modulos/Soporte/Soporte";
import { LicitacionesPresentes } from "../../Modulos/LicitacionesPresentes/LicitacionesPresentes";
import { Consulta } from "../../Modulos/Consulta/Consulta/Consulta";
import { Usuarios } from "../../Modulos/Usuarios/Usuarios";

export default function Routing() {
  return (
    <Router>
      <Routes>
        <Route
          index
          element={
            <ProtectedRoute>
              <Dashboard>
                <Tablero />
              </Dashboard>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard>
                <Tablero />
              </Dashboard>
            </ProtectedRoute>
          }
        />

        <Route
          path="/licitacionesPresentes"
          element={
            <ProtectedRoute>
              <Dashboard>
                <LicitacionesPresentes />
              </Dashboard>
            </ProtectedRoute>
          }
        />

        <Route
          path="/consulta"
          element={
            <ProtectedRoute>
              <Dashboard>
                <Consulta />
              </Dashboard>
            </ProtectedRoute>
          }
        />

        <Route path="/login" element={<Login />} />
        <Route path="/passwordReset" element={<RestablecerPassword />} />
        <Route
          path="/formularioRegistro"
          element={
            <RegisterContextProvider>
              <ForumularioRegistro />
            </RegisterContextProvider>
          }
        />

        <Route
          path="/soporte"
          element={
            <ProtectedRoute>
              <Dashboard>
                <Soporte />
              </Dashboard>
            </ProtectedRoute>
          }
        />

        <Route
          path="/empresa"
          element={
            <ProtectedRoute>
              <Dashboard>
                <Empresa />
              </Dashboard>
            </ProtectedRoute>
          }
        />

        <Route
          path="/usuarios"
          element={
            <ProtectedRoute>
              <Dashboard>
                <Usuarios />
              </Dashboard>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

import { gql } from "@apollo/client";

export const GET_METRICAS = gql`
  query Query($emailUsuario: String) {
    metricas(emailUsuario: $emailUsuario) {
      montoLicitaciones
      contadorLicitacionesTotal
      contadorEstadoLicitaciones0
      contadorEstadoLicitaciones1
      contadorEstadoLicitaciones2
      contadorEstadoLicitaciones3
    }
  }
`;

import { ChangeEvent, useState } from "react";

export function useForm<T>(initState: T) {
  const [formulario, setFormulario] = useState(initState);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { target } = event;
    const { value, name } = target;

    setFormulario({
      ...formulario,
      [name]: value.trim(),
    });
  };

  return {
    formulario,
    handleChange,
  };
}

import React, { useState, useContext, useEffect, useMemo } from "react";

import Chart from "./Chart";
import Acumulado from "./Acumulado";
import CantidadDestacadas from "./CantidadDestacadas";
import Destacadas from "./Destacadas";

import { Calendario } from "./Calendario/Calendario";
import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  createTheme,
  Grid,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";

import { gql, useMutation, useQuery } from "@apollo/client";
import { GET_LICITACIONES_QUERY } from "./Query/QueryLicitaciones";
import { GET_METRICAS } from "./Query/QueryMetricas";
import { useUserContext } from "../../utils/hooks/contexts/userContext";

import { MUTATION_CAMBIAR_ESTADO } from "./Query/MutationCambiarEstado";
import { MUTATION_ELIMINAR_LICITACION_DESTACADA } from "./Query/MutationEliminarLicitacionDestacada";
import Title from "./Title";
const theme = createTheme();

const root = {
  display: "flex",
};
const content = {
  display: "flex",
  flexGrow: 1,
  height: "100%",
};
const container = {
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
};
const paper = {
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
};
const resumen = {
  height: 300,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",

  width: "100%",
  maxWidth: 5000,
};
const fichas = {
  height: "100%",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "space-between",
  width: "30%",
};
const cantidad1 = {
  width: "100%",
  height: "40%",
  padding: theme.spacing(1),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
const acumulado1 = {
  width: "100%",
  height: "55%",
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};
const calendario = {
  height: "100%",
  minWidth: "fit-content",
  padding: theme.spacing(2),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const grafico = {
  height: "100%",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  width: "50%",
};

export interface licitacion {
  numeroCartel: string;
  numeroProcedimiento: string;
  institucion: string;
  descripcion: string;
  presupuesto: string;
  fechaCierreRecepcion: string;
  garantiaParticipacion: string;
  estado: number;
}

export default function Tablero() {
  const { User } = useUserContext();
  const [cantidad, setCantidad] = useState("0");
  const [acomulado, setAcomulado] = useState("0");

  const [licitaciones, setLicitaciones] = useState<any[]>([]);

  const [page, setPage] = React.useState(0);
  const [countItems, setCountItems] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [cambiarEstadoMutacion] = useMutation(MUTATION_CAMBIAR_ESTADO);
  const [eliminarLicitacionMutacion] = useMutation(
    MUTATION_ELIMINAR_LICITACION_DESTACADA
  );

  const [refrescar, setRefrescar] = React.useState(true);

  const handleChange = async (
    event: React.ChangeEvent<{ value: unknown }>,
    numeroProcedimiento: string
  ) => {};

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Licitaciones = useQuery(GET_LICITACIONES_QUERY, {
    variables: {
      pageSize: rowsPerPage.toString(),
      pageIndex: (page + 1).toString(),
      emailUsuario: User.email,
    },
  });

  useEffect(() => {
    let licitaciones: licitacion[] = [];

    if (Licitaciones.data) {
      const licit =
        (Licitaciones.data.licitaciones.items as licitacion[]) ?? [];

      licit.forEach((licitacion: licitacion) => {
        let fecha = new Date(parseInt(licitacion.fechaCierreRecepcion));
        let fechaFormateada = `${fecha.getDate()}/${
          fecha.getMonth() + 1
        }/${fecha.getFullYear()}`;
        if (!licitacion.fechaCierreRecepcion) fechaFormateada = "";
        licitaciones.push({
          numeroCartel: "",
          numeroProcedimiento: licitacion.numeroProcedimiento,
          institucion: licitacion.institucion,
          descripcion: licitacion.descripcion,
          presupuesto: licitacion.presupuesto,
          fechaCierreRecepcion: fechaFormateada,
          garantiaParticipacion: licitacion.garantiaParticipacion,
          estado: licitacion.estado,
        });
      });

      setLicitaciones(licitaciones);
      setCountItems(parseInt(Licitaciones.data.licitaciones.countItems ?? "0"));
    }
  }, [Licitaciones.data, refrescar]);

  const Metricas = useQuery(GET_METRICAS, {
    variables: {
      emailUsuario: User.email,
    },
  });

  useEffect(() => {
    if (Metricas.data) {
      setAcomulado(Metricas.data.metricas.montoLicitaciones ?? "0");
      setCantidad(Metricas.data.metricas.contadorLicitacionesTotal ?? "0");
    }
  }, [Metricas.data, refrescar]);

  const cambiarEstadoLicitacion = async (
    numeroProced: string,
    estado: string
  ) => {
    await cambiarEstadoMutacion({
      variables: {
        emailUser: User.email,
        cambiarEstado: estado.toString(),
        numeroProcedimiento: numeroProced,
      },
    });

    await Metricas.refetch();
    setRefrescar(!refrescar);
  };

  const borrarLicitacion = async (numeroProcedimiento: string) => {
    await eliminarLicitacionMutacion({
      variables: {
        emailUser: User.email,
        numeroProcedimiento: numeroProcedimiento,
      },
    });

    await Metricas.refetch();
    await Licitaciones.refetch();
    setRefrescar(!refrescar);
  };

  return (
    <Container style={{ maxWidth: 5000 }} sx={container}>
      <Grid container spacing={3}>
        <Container style={{ maxWidth: 5000, padding: 12 }} sx={resumen}>
          {/* Cantidad y Total Acumulado */}
          <Box sx={fichas}>
            <Paper sx={cantidad1}>
              <CantidadDestacadas cantidad={cantidad} />
            </Paper>
            <Paper sx={acumulado1}>
              <Acumulado acumulado={acomulado} />
            </Paper>
          </Box>
          {/* Calendario */}
          <Paper sx={calendario}>
            <Calendario licitaciones={licitaciones} />
          </Paper>
          {/* Grafico */}
          <Paper sx={grafico}>
            <Typography align="center" variant="h6" color="primary">
              Estado de licitaciones destacadas
            </Typography>
            <Chart refrescar={refrescar} />
          </Paper>
        </Container>
        <Container style={{ maxWidth: 5000, padding: 12, flexGrow: 1 }}>
          <Grid item container>
            {/* Licitaciones destacadas */}
            <Grid item xs={12}>
              <Paper sx={paper}>
                {licitaciones.length > 0 ? (
                  <>
                    <Destacadas
                      handleChange={handleChange}
                      licitaciones={licitaciones}
                      borrarLicitacion={borrarLicitacion}
                      cambiarEstadoLicitacion={cambiarEstadoLicitacion}
                    />
                    <TablePagination
                      component="div"
                      count={countItems}
                      page={page}
                      onPageChange={handleChangePage}
                      rowsPerPage={rowsPerPage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </>
                ) : (
                  <Title>Aun no has destacado ningula licitacion</Title>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Grid>

      <Backdrop open={Licitaciones.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}

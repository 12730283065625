import { gql } from "@apollo/client";

export const SEND_LICIT_PRESENTES = gql`
  query Query(
    $pageSize: String
    $pageIndex: String
    $buscarPorFecha: String
    $emailUsuario: String
  ) {
    licitacionesFechas(
      pageSize: $pageSize
      pageIndex: $pageIndex
      buscarPorFecha: $buscarPorFecha
      emailUsuario: $emailUsuario
    ) {
      pageSize
      pageIndex
      pageCount
      countItems
      items {
        numeroProcedimiento
        institucion
        descripcion
        presupuesto
        fechaCierreRecepcion
        garantiaParticipacion
        numeroCartel
      }
    }
  }
`;

import React, { useState } from "react";

import { Box, Grid } from "@mui/material";
import { VistaDias } from "./VistaDias";
import { VistaMeses } from "./VistaMeses";
import { licitacion } from "../Tablero";
import { BarraSuperior } from "./BarraSuperior";
import { createTheme } from "@mui/material";

const theme = createTheme();

const cuadro = {
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: 5,
  width: "20.5rem",
  height: "-webkit-fill-available",
};
const container = {
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
};
const paper = {
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
};
const fixedHeight = {
  height: 260,
};

export const Calendario = (props: { licitaciones: licitacion[] }) => {
  const hoy = new Date();
  const [vista, setVista] = useState("dias");
  const [mes, setMes] = useState(hoy.getMonth());
  const [ano, setAno] = useState(hoy.getFullYear());

  const cambiarVista = (nuevaVista: string) => {
    setVista(nuevaVista);
  };

  const renderVista = () => {
    switch (vista) {
      case "dias":
        return (
          <VistaDias licitaciones={props.licitaciones} mes={mes} ano={ano} />
        );
      case "meses":
        return <VistaMeses setVista={setVista} setMes={setMes} />;
    }
  };

  return (
    <Box>
      <Grid container sx={cuadro}>
        <BarraSuperior
          vista={vista}
          cambiarVista={cambiarVista}
          mes={mes}
          ano={ano}
          setMes={setMes}
          setAno={setAno}
        />
        {renderVista()}
      </Grid>
    </Box>
  );
};

import firebase from 'firebase/app';

export interface Session {
  user: firebase.User | null;
  id: string;
  client: string;
  vigencia: boolean;
  signOut: () => void | undefined;
  loading: boolean;
  admin: boolean;
  urlParams?: any;
}

export const initialSession: Session = {
  user: null,
  id: '',
  client: '',
  vigencia: true,
  signOut: () => {},
  loading: false,
  admin: false
}
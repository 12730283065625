import React, { useState, useContext, useEffect } from "react";

import Resultados from "../Resultados/Resultados";

import * as Styles from "./ConsultaStyles";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";
import { Licitacion } from "../../../models/Licitacion";
import { useMutation, useQuery } from "@apollo/client";
import { SEARCH_LICITACIONES } from "../Query/QueryBuscarLicitaciones";
import SearchBar from "../SearchBar/SearchBar";
import { MUTATION_DESTACAR_LICITACION } from "../Query/MutationDestacarLicitacion";
import { useUserContext } from "../../../utils/hooks/contexts/userContext";

export const Consulta = () => {
  const { User } = useUserContext();
  const [sendQuery, setSendQuery] = useState(true);
  const [campoVacio, setCampoVacio] = useState(false);
  const [palabras, setPalabras] = useState("");
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [countItems, setCountItems] = React.useState(0);
  const [licitaciones, setLicitaciones] = useState(new Array<Licitacion>());

  const [licitacionDestacada, setLicitacionDestacada] = useState(false);
  const [existelicitacionDestacada, setExisteLicitacionDestacada] =
    useState(false);

  const LicitacionQuery = useQuery(SEARCH_LICITACIONES, {
    variables: {
      pageSize: rowsPerPage.toString(),
      pageIndex: (page + 1).toString(),
      search: palabras,
    },
    skip: sendQuery,
  });

  useEffect(() => {
    let licitaciones: Licitacion[] = [];
    if (LicitacionQuery.data) {
      const licit =
        (LicitacionQuery.data.licitaciones.items as Licitacion[]) ??
        Array<Licitacion>();

      licit.forEach((licitacion: Licitacion) => {
        let fecha = new Date(parseInt(licitacion.fechaCierreRecepcion));
        let fechaFormateada = `${fecha.getDate()}/${
          fecha.getMonth() + 1
        }/${fecha.getFullYear()}`;

        licitaciones.push({
          numeroCartel: licitacion.numeroCartel,
          numeroProcedimiento: licitacion.numeroProcedimiento,
          institucion: licitacion.institucion,
          descripcion: licitacion.descripcion,
          presupuesto: licitacion.presupuesto,
          fechaCierreRecepcion: fechaFormateada,
          garantiaParticipacion: licitacion.garantiaParticipacion,
        });
      });

      setLicitaciones(licitaciones);
      setCountItems(
        parseInt(LicitacionQuery.data.licitaciones.countItems) ?? 0
      );
    }

    setSendQuery(true);
  }, [LicitacionQuery.data]);

  async function buscarLicitaciones(propspalabras: string) {
    if (propspalabras === "") {
      setCampoVacio(true);
      return;
    }

    setPalabras(propspalabras);
    setSendQuery(false);
  }

  const handleChangePage = (event: any, newPage: number) => {
    setSendQuery(false);
    LicitacionQuery.refetch();
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setSendQuery(false);
    LicitacionQuery.refetch();
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [destacarLicit, { data }] = useMutation(MUTATION_DESTACAR_LICITACION);

  useEffect(() => {
    if (data) {
      const estado = (data.guardarLicitacionDestacada.mensaje as string) ?? "";

      if (estado === "Exito") setLicitacionDestacada(true);

      if (estado === "yaExiste") setExisteLicitacionDestacada(true);
    }
  }, [data]);

  const destacar = async (numeroProcedimiento: string) => {
    await destacarLicit({
      variables: {
        emailUsuario: User.email,
        numeroProcedimiento: numeroProcedimiento,
      },
    });
  };

  return (
    <Container maxWidth="xl" sx={Styles.container}>
      <Grid container spacing={3}>
        <Paper sx={Styles.paper}>
          <Container>
            <Typography variant="h6" color="primary">
              Ingrese el número de procedimiento, palabras relacionadas a la
              descripción de una licitación o el nombre de una institución.
            </Typography>
          </Container>
          <Container sx={Styles.instruccion}>
            <SearchBar buscar={buscarLicitaciones} />
          </Container>
        </Paper>
        {licitaciones.length > 0 ? (
          <Paper sx={Styles.paper}>
            <Resultados licitaciones={licitaciones} destacar={destacar} />
            <Box alignItems="center" display="flex" flexDirection="column">
              <TablePagination
                component="div"
                count={countItems}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Paper>
        ) : (
          <Paper sx={Styles.paper}>
            <Typography variant="h6" align="center" gutterBottom={true}>
              No encontramos licitaciones relacionadas a la búsqueda que
              realizó.
            </Typography>
          </Paper>
        )}
      </Grid>

      <Dialog open={campoVacio}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Es necesario ingresar informacion.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setCampoVacio(false)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop open={LicitacionQuery.loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Dialog open={licitacionDestacada}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            La licitacion se destaco con exito
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setLicitacionDestacada(false)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={existelicitacionDestacada}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            La licitacion ya se encuentra destacada
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setExisteLicitacionDestacada(false)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

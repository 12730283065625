import { gql } from "@apollo/client";

export const SEND_USUARIOS_INFO = gql`
  query Query($emailUsuario: String) {
    obtenerInformacionUsuarios(emailUsuario: $emailUsuario) {
      informacionUsuario1
      informacionUsuario2
      informacionUsuario3
      informacionUsuarioPrincipal
    }
  }
`;

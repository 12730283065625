import React from "react";
import { Grid, Button, IconButton, createTheme } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const theme = createTheme();

const root = {
  height: "fit-content",
};
const flecha = {
  fontSize: "1.25rem",
  color: theme.palette.primary.main,
};
const titulo = {
  alignSelf: "center",
  textAlign: "center",
};

export const BarraSuperior = (props: {
  vista: string;
  cambiarVista: (vista: string) => void;
  mes: number;
  ano: number;
  setMes: (arg: number) => void;
  setAno: (arg: number) => void;
}) => {
  const meses = [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "setiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ];

  const adelantarMes = () => {
    let mesSiguiente = props.mes + 1;
    if (mesSiguiente <= 11) {
      props.setMes(mesSiguiente);
    } else {
      props.setMes(0);
      props.setAno(props.ano + 1);
    }
  };

  const regresarMes = () => {
    let mesAnterior = props.mes - 1;
    if (mesAnterior >= 0) {
      props.setMes(mesAnterior);
    } else {
      props.setMes(11);
      props.setAno(props.ano - 1);
    }
  };

  const adelantarAno = () => {
    props.setAno(props.ano + 1);
  };

  const regresarAno = () => {
    props.setAno(props.ano - 1);
  };

  const funcionFlechaDerecha = () => {
    switch (props.vista) {
      case "dias":
        return adelantarMes;
      case "meses":
        return adelantarAno;
    }
  };

  const funcionFlechaIzquierda = () => {
    switch (props.vista) {
      case "dias":
        return regresarMes;
      case "meses":
        return regresarAno;
    }
  };

  const cambiarVista = () => {
    switch (props.vista) {
      case "dias":
        props.cambiarVista("meses");
        break;
      case "meses":
        props.cambiarVista("dias");
        break;
    }
  };

  const textoBoton = () => {
    switch (props.vista) {
      case "dias":
        return `${meses[props.mes]} ${props.ano}`;
      case "meses":
        return `${props.ano}`;
    }
  };

  return (
    <Grid item container sx={root}>
      <Grid item xs={2} style={{ textAlign: "center" }}>
        <IconButton
          style={{ borderRadius: "10%" }}
          onClick={funcionFlechaIzquierda()}
        >
          <ChevronLeftIcon sx={flecha} />
        </IconButton>
      </Grid>
      <Grid item xs={8} sx={titulo}>
        <Button color="primary" onClick={cambiarVista}>
          {textoBoton()}
        </Button>
      </Grid>
      <Grid item xs={2} style={{ textAlign: "right" }}>
        <IconButton
          style={{ borderRadius: "10%" }}
          onClick={funcionFlechaDerecha()}
        >
          <ChevronRightIcon sx={flecha} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

import React, { useState, useContext, useEffect } from "react";

import DatosEmpresariales from "./DatosEmpresariales";
import Usuarios from "./Usuarios";
import TerminosYCondiciones from "./TerminosYCondiciones";

import { RegisterContext } from "../../utils/hooks/contexts/registerContext";
import {
  Button,
  Container,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { mutacionRegistro } from "./Registro";
import firebase from "firebase/app";
import "firebase/auth";
import { useNavigate } from "react-router-dom";

const theme = createTheme();

const root = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const paper = {
  marginTop: theme.spacing(8),
  marginBottom: theme.spacing(8),
  display: "flex",
  flexDirection: "column",
  alignItems: "space-between",
  justifyContent: "flex-start",
  width: "100%",
  padding: 6,
};

const botones = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  marginTop: theme.spacing(6),
};
const enfasis = {
  fontWeight: 600,
};

function password() {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function getSteps() {
  return ["Datos Empresariales", "Usuarios", "Terminos y Condiciones"]; //, 'Método de pago'];
}

export default function ForumularioRegistro() {
  const [addTodo, { error }] = useMutation(mutacionRegistro);
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [err, setError] = useState(false);
  const [correoError, setCorreoError] = useState(false);
  const [email, setEmail] = useState("");
  const steps = getSteps();
  const { state, dispatch } = useContext(RegisterContext);

  async function revisarFormulario() {
    if (
      state.name === "" ||
      state.id.length < 10 ||
      state.mainUser === "" ||
      state.mainEmail === "" ||
      state.description === ""
    ) {
      return false;
    } else {
      let correosYaExisten: boolean = false;
      let emailexistente = "";

      let correos = [];
      let correo: string[];

      try {
        correo = await firebase
          .auth()
          .fetchSignInMethodsForEmail(state.mainEmail);
        emailexistente = state.mainEmail;
        correos.push(correo);
      } catch (error) {}

      for (let i = 0; i < 3; i++) {
        if (state.users[i]?.email.length !== 0) {
          try {
            correo = await firebase
              .auth()
              .fetchSignInMethodsForEmail(state.users[i]?.email);
            emailexistente = state.users[i]?.email;
            correos.push(correo);
          } catch (error) {}
        }
      }

      correos.forEach((correo) => {
        if (correo.length !== 0) {
          correosYaExisten = true;
        }
      });

      if (correosYaExisten) {
        setEmail(emailexistente);
        return "correo";
      } else {
        return true;
      }
    }
  }

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      return;
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    window.scrollTo(0, 0);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo(0, 0);
  };

  async function enviarFormulario() {
    setLoading(true);
    let form = await revisarFormulario();
    if (!form) {
      setOpen(true);
      setLoading(false);
      return;
    } else if (form === "correo") {
      setCorreoError(true);
      setLoading(false);
      return;
    }

    const fechaRegistro = new Date().getTime().toString();
    const passwordUsuarioPrincipal = password();
    const password1 = password();
    const password2 = password();
    const password3 = password();

    try {
      await firebase
        .auth()
        .createUserWithEmailAndPassword(
          state.mainEmail,
          passwordUsuarioPrincipal
        );

      let numeros = [0, 1, 2];
      let passwords = [password1, password2, password3];
      for (let numero of numeros) {
        if (state.users[numero].email !== "") {
          await firebase
            .auth()
            .createUserWithEmailAndPassword(
              state.users[numero].email,
              passwords[numero]
            );
        }
      }

      await addTodo({
        variables: {
          cedula: state.id,
          nombre: state.name,
          provincia: state.province,
          canton: state.canton,
          descripcion: state.description,
          productos: state.products,
          antiProductos: state.antiProducts,
          nombreUsuarioPrincipal: state.mainUser,
          correoUsuarioPrincipal: state.mainEmail,
          passwordUsuarioPrincipal: passwordUsuarioPrincipal,
          nombre1: state.users[0]?.name,
          correo1: state.users[0]?.email,
          password1: password1,
          nombre2: state.users[1]?.name,
          correo2: state.users[1]?.email,
          password2: password2,
          nombre3: state.users[2]?.name,
          correo3: state.users[2]?.email,
          password3: password3,
          keywords: "",
          fechaRegistro: fechaRegistro,
          fechaAbandono: "0",
        },
      });

      setEnviado(true);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }

  async function aceptarEnvio() {
    setEnviado(false);

    navigate("/dashboard");
  }

  const stepsMap: { [key: number]: JSX.Element } = {
    0: <DatosEmpresariales />,
    1: <Usuarios />,
    2: <TerminosYCondiciones />,
  };

  return (
    <Container component="main" maxWidth="xl" sx={root}>
      <Paper sx={paper}>
        <Typography
          color="primary"
          variant="h3"
          align="left"
          gutterBottom={true}
        >
          Formulario de registro
        </Typography>
        <Stepper sx={{ margin: 3 }} activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>

        {stepsMap[activeStep]}
        <Container sx={botones}>
          <Button
            onClick={handleBack}
            href={activeStep === 0 ? "/" : ""}
            variant="contained"
            color="secondary"
          >
            Regresar
          </Button>
          <Button
            disabled={loading}
            onClick={
              activeStep === steps.length - 1 ? enviarFormulario : handleNext
            }
            href={activeStep === steps.length - 1 ? "" : ""}
            variant="contained"
            color="primary"
          >
            {activeStep === steps.length - 1 ? "Finalizar" : "Siguiente"}
          </Button>
        </Container>
      </Paper>
      <Dialog onClose={() => setOpen(false)} open={open}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Es necesario rellenar todos los campos obligatorios (*).
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setOpen(false)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={enviado}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Registro exitoso.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={aceptarEnvio} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={err}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Hubo un error con el proceso de registro, por favor inténtelo más
            tarde.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setError(false)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={correoError}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            El correo <strong>{email}</strong> ya está registrado en la
            aplicación. Por favor digita uno diferente para continuar
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setCorreoError(false)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

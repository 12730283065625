import React, { useState } from "react";

import firebase from "firebase/app";
import "firebase/auth";

import licitanauta from "../../utils/astronauta_morado.png";
import * as Styles from "./Styles";
import {
  Avatar,
  Box,
  Button,
  Container,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Link,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://licitanauta.com/">
        Licitanauta C.R.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export const RestablecerPassword = () => {
  const [email, setEmail] = useState("");
  const [enviado, setEnviado] = useState(false);
  const [error, setError] = useState(false);
  const [errorServidor, setErrorServidor] = useState(false);

  const navigate = useNavigate();

  function validarEmail(email: string): boolean {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(email)) {
      return false;
    }
    return true;
  }

  let sendEmail = async () => {
    let validate = validarEmail(email);
    if (validate) {
      setEnviado(true);

      await firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          setEnviado(true);
        })
        .catch(() => {
          setErrorServidor(true);
        });
    } else {
      setError(true);
      return;
    }
  };

  const confirmarEnvio = () => {
    setEnviado(false);

    navigate("/login");
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Container sx={Styles.paper} disableGutters={true}>
        <Avatar sx={Styles.avatar}>
          <img src={licitanauta} height="40" width="40" alt="licitanauta" />
        </Avatar>
        <Typography component="h1" variant="h5" color="primary">
          Licitanauta
        </Typography>
        <Paper sx={Styles.form}>
          <TextField
            inputProps={{ "data-testid": "email-input" }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event) => setEmail(event.target.value)}
          />
          <Button
            data-testid="submit-button"
            fullWidth
            variant="contained"
            color="primary"
            sx={Styles.submit}
            onClick={() => {}}
          >
            Restablecer contraseña
          </Button>
        </Paper>
      </Container>
      <Box mt={8}>
        <Copyright />
      </Box>

      <Dialog open={enviado}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Se envió un correo de restablecimiento de contraseña a su dirección
            de correo .
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={confirmarEnvio} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={error}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Escriba una dirección de correo válida.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            data-testid="submit-button"
            variant="contained"
            onClick={() => setError(false)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={errorServidor}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Hubo un error con el proceso de registro, por favor inténtelo más
            tarde.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setErrorServidor(false)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

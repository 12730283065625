import React, { useEffect, useState } from "react";
import { Button, Box } from "@mui/material";

import { InfoDia } from "./InfoDia";
import { licitacion } from "../Tablero";
import { createTheme } from "@mui/material";
import { useQuery } from "@apollo/client";
import { SEND_CALENDARIO_INFO_QUERY } from "../Query/QueryCalendario";
import { useUserContext } from "../../../utils/hooks/contexts/userContext";

const theme = createTheme();

const boton = {
  borderRadius: 20,
  minWidth: "-webkit-fill-available",
};
const botonEspecial = {
  borderRadius: 20,
  minWidth: "-webkit-fill-available",
  backgroundColor: "rgba(52, 0, 70, 0.2)",
};
const botonEventos = {
  borderRadius: 20,
  minWidth: "-webkit-fill-available",
  backgroundColor: "rgba(14, 121, 178, 0.2)",
};

interface licitacionesCierreInfo {
  numeroProcedimiento: string;
  descripcion: string;
}

export const Dia = (props: {
  licitaciones: licitacion[] | undefined;
  dia: number;
  mes: number;
  ano: number;
}) => {
  const { User } = useUserContext();
  const [sendQuery, setSendQuery] = useState(true);
  const [fecha, setFecha] = useState("");
  const [licitCierreInfo, setLicitCierreInfo] = useState(
    new Array<licitacionesCierreInfo>()
  );

  const { loading, data, refetch } = useQuery(SEND_CALENDARIO_INFO_QUERY, {
    variables: {
      fecha: fecha,
      emailUsuario: User.email,
    },
    skip: sendQuery,
  });

  useEffect(() => {
    if (data) {
      const info = data.calendarioInfo as licitacionesCierreInfo[];

      setLicitCierreInfo(info);
    }

    setSendQuery(true);
  }, [data]);

  const hoy = new Date();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const open = Boolean(anchorEl);

  const diaEsHoy = () => {
    if (
      hoy.getDate() === props.dia &&
      hoy.getMonth() === props.mes &&
      hoy.getFullYear() === props.ano
    ) {
      return true;
    } else {
      return false;
    }
  };

  const diaTieneEventos = () => {
    if (props.licitaciones) {
      return true;
    } else {
      return false;
    }
  };

  const definirClase = () => {
    if (diaEsHoy()) {
      return botonEspecial;
    } else if (diaTieneEventos()) {
      return botonEventos;
    } else {
      return boton;
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setSendQuery(false);
    setFecha(new Date(props.ano, props.mes, props.dia).getTime().toString());
    refetch();
  };

  return (
    <Box>
      <Button
        color="primary"
        sx={{ ...definirClase() }}
        size="small"
        onClick={handleClick}
      >
        {props.dia}
      </Button>
      {licitCierreInfo.length > 0 ? (
        <InfoDia
          licitaciones={licitCierreInfo}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
        />
      ) : null}
    </Box>
  );
};

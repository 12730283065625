import {
  Button,
  createTheme,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

const theme = createTheme();
const tableHeader = {
  color: theme.palette.primary.main,
};
const cell = {
  padding: "6px 12px 6px 12px",
};

interface licitacion {
  numeroCartel: string;
  numeroProcedimiento: string;
  institucion: string;
  descripcion: string;
  presupuesto: string;
  fechaCierreRecepcion: string;
  garantiaParticipacion: string;
  estado: number;
}

interface PropsDestacadas {
  licitaciones: licitacion[];
  destacar: (numeroProcedimiento: string) => void;
}

function link(numeroCartel: string) {
  return `https://www.sicop.go.cr/moduloPcont/pcont/ctract/es/CE_CEJ_ESQ002.jsp?cartelNo=${numeroCartel}&cartelSeq=00`;
}

export default function Presentes(props: PropsDestacadas) {
  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={`${tableHeader} ${cell}`}>
              Numero Procedimiento
            </TableCell>
            <TableCell className={`${tableHeader} ${cell}`}>
              Institución
            </TableCell>
            <TableCell className={`${tableHeader} ${cell}`}>
              Descripción
            </TableCell>
            <TableCell className={`${tableHeader} ${cell}`}>
              Presupuesto
            </TableCell>
            <TableCell className={`${tableHeader} ${cell}`}>
              Fecha Cierre
            </TableCell>
            <TableCell className={`${tableHeader} ${cell}`}>
              Garantía Participación
            </TableCell>
            <TableCell className={`${tableHeader} ${cell}`}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.licitaciones.map((licitacion: licitacion, index) => (
            <TableRow key={index} hover={true}>
              <TableCell sx={cell}>
                <Link
                  target="_blank"
                  href={link(licitacion.numeroCartel)}
                  color="secondary"
                >
                  {licitacion.numeroProcedimiento}
                </Link>
              </TableCell>
              <TableCell sx={cell}>{licitacion.institucion}</TableCell>
              <TableCell sx={cell}>{licitacion.descripcion}</TableCell>
              <TableCell sx={cell}>{licitacion.presupuesto}</TableCell>
              <TableCell sx={cell}>{licitacion.fechaCierreRecepcion}</TableCell>
              <TableCell sx={cell}>
                {licitacion.garantiaParticipacion}
              </TableCell>
              <TableCell sx={cell}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => props.destacar(licitacion.numeroProcedimiento)}
                >
                  Destacar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

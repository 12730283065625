import React, { useState, useEffect, useContext } from "react";

import { usuario, usuarios } from "./Usuarios";

import * as firebase from "firebase/app";
import "firebase/auth";
import * as Styles from "./Styles";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

const user: usuario = {
  nombre: "",
  correo: "",
};

const users: usuarios = {
  1: user,
  2: user,
  3: user,
  4: user,
};

interface PropsUsuariosAdmin {
  usuariosActuales: usuarios;
  refrescar: boolean;
}

export default function UsuariosNoAdmin(props: PropsUsuariosAdmin) {
  const [usuariosModificados, setUsuariosModificados] = useState(users);
  useEffect(() => {
    setUsuariosModificados(props.usuariosActuales);
  }, [props.usuariosActuales, props.refrescar]);

  const handleChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    usuario: number,
    set: Function,
    nombreOcorreo: string
  ) => {
    setUsuariosModificados({
      ...usuariosModificados,
      [usuario]: {
        ...usuariosModificados[usuario],
        [nombreOcorreo]: event.target.value,
      },
    });
  };

  return (
    <Container maxWidth="lg" sx={Styles.container}>
      <Paper sx={Styles.paper}>
        <Typography sx={Styles.titulo} variant="h4">
          Usuarios registrados
        </Typography>
        <Typography color="primary" variant="h6" align="center">
          Solo el administrador de la empresa puede hacer cambios a los datos de
          los usuarios.
        </Typography>

        <Grid container spacing={2}>
          {[1, 2, 3, 4].map((numero) => (
            <Grid
              container
              item
              xs={12}
              spacing={1}
              sx={Styles.usuario}
              key={`usuario${numero}`}
            >
              <Grid item sm={5}>
                <Typography sx={Styles.etiqueta}>
                  Nombre{numero === 1 ? " Administrador" : ""}
                </Typography>
              </Grid>
              <Grid item sm={7}>
                <Typography sx={Styles.etiqueta}>
                  Correo{numero === 1 ? " Administrador" : ""}
                </Typography>
              </Grid>
              <Grid item sm={5}>
                <TextField
                  disabled={true}
                  onChange={(e) =>
                    handleChange(e, numero, setUsuariosModificados, "nombre")
                  }
                  value={usuariosModificados[numero].nombre}
                  sx={Styles.nombre}
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={7}>
                <TextField
                  disabled={true}
                  onChange={(e) =>
                    handleChange(e, numero, setUsuariosModificados, "correo")
                  }
                  value={usuariosModificados[numero].correo}
                  sx={Styles.correo}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Container>
  );
}

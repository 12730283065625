import React, { useMemo } from "react";
import { Grid } from "@mui/material";

import { licitacion } from "../Tablero";
import { Dia } from "./Dia";
import { TituloDia } from "./TituloDia";

const content = {
  flexGrow: 1,
  width: "100%",
  overflow: "auto",
};
const boton = {
  borderRadius: 20,
  width: "2.9rem",
};
const titulos = {
  flexDirection: "row",
  justifyContent: "space-around",
};

const calcularDiasEnMesActual = (ano: number, mes: number) => {
  return 32 - new Date(ano, mes, 32).getDate();
};

const calcularDiasEnMesAnterior = (ano: number, mes: number) => {
  let mesAnterior = mes > 0 ? mes - 1 : 11;
  return 32 - new Date(ano, mesAnterior, 32).getDate();
};

const calcularPrimerDia = (ano: number, mes: number) => {
  return new Date(ano, mes).getDay();
};

const calcularDiasExtraMesAnterior = (primerDia: number) => {
  if (primerDia === 1) {
    return 0;
  } else {
    return primerDia - 1;
  }
};

const calcularDiasDelMes = (
  diasEnMesActual: number,
  diasEnMesAnterior: number,
  diasExtraMesAnterior: number
) => {
  let dias: number[] = [];

  for (let i = diasExtraMesAnterior; i > 0; i--) {
    dias.push(diasEnMesAnterior + 1 - i);
  }
  for (let i = 1; i <= diasEnMesActual; i++) {
    dias.push(i);
  }
  if (dias.length < 35) {
    let diasFaltantes = 35 - dias.length;
    for (let i = 1; i <= diasFaltantes; i++) {
      dias.push(i);
    }
  }
  return dias;
};

const ordenarLicitaciones = (licitaciones: licitacion[]) => {
  let fecha: string;
  let dicTemporal: { [key: string]: licitacion[] } = {};
  licitaciones.forEach((licitacion) => {
    fecha = licitacion.fechaCierreRecepcion.substring(0, 10);
    if (fecha in dicTemporal) {
      dicTemporal[fecha].push(licitacion);
    } else {
      dicTemporal[`${fecha}`] = [licitacion];
    }
  });
  return dicTemporal;
};

const dias = ["L", "K", "M", "J", "V", "S", "D"];

export const VistaDias = (props: {
  licitaciones: licitacion[];
  mes: number;
  ano: number;
}) => {
  const dicLicitaciones = useMemo(
    () => ordenarLicitaciones(props.licitaciones),
    [props.licitaciones]
  );
  const primerDia = useMemo(
    () => calcularPrimerDia(props.ano, props.mes),
    [props.mes, props.ano]
  );
  const diasEnMesActual = useMemo(
    () => calcularDiasEnMesActual(props.ano, props.mes),
    [props.mes, props.ano]
  );
  const diasEnMesAnterior = useMemo(
    () => calcularDiasEnMesAnterior(props.ano, props.mes),
    [props.mes, props.ano]
  );
  const diasExtraMesAnterior = useMemo(
    () => calcularDiasExtraMesAnterior(primerDia),
    [primerDia]
  );
  const diasDelMes = useMemo(
    () =>
      calcularDiasDelMes(
        diasEnMesActual,
        diasEnMesAnterior,
        diasExtraMesAnterior
      ),
    [diasEnMesActual, diasEnMesAnterior, diasExtraMesAnterior]
  );

  return (
    <Grid item container sx={content}>
      <Grid item container sx={titulos}>
        {dias.map((dia) => {
          return (
            <Grid item key={dia}>
              <TituloDia dia={dia} />
            </Grid>
          );
        })}
      </Grid>
      {diasDelMes.map((dia, index) => {
        let licitaciones;
        let formatoDia = dia < 10 ? `0${dia}` : dia;
        let formatoMes = props.mes < 10 ? `0${props.mes + 1}` : props.mes + 1;
        if (`${formatoDia}/${formatoMes}/${props.ano}` in dicLicitaciones) {
          licitaciones =
            dicLicitaciones[`${formatoDia}/${formatoMes}/${props.ano}`];
        }
        return (
          <Grid sx={boton} key={`${index}-${dia}`}>
            <Dia
              licitaciones={licitaciones}
              dia={dia}
              mes={props.mes}
              ano={props.ano}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

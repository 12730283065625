import { createTheme } from "@mui/material";

const theme = createTheme();

export const paper = {
  marginTop: theme.spacing(6),
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};
export const avatar = {
  margin: theme.spacing(1),
  backgroundColor: "white", //theme.palette.secondary.main,
};
export const form = {
  width: "100%", // Fix IE 11 issue.
  marginTop: theme.spacing(6),
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  padding: 4,
};
export const submit = {
  margin: theme.spacing(3, 0, 2),
};
export const recordarme = {
  display: "flex",
  marginLeft: 1,
};

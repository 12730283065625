import { Grid, Typography } from "@mui/material";
import React from "react";

import { EntradaEmpresarial } from "./EntradaEmpresarial";

const root = {
  margin: 0,
  marginLeft: 7,
};

const inputValues: string[] = [
  "name",
  "id",
  "mainUser",
  "mainEmail",
  "province",
  "canton",
  "description",
  "products",
  "antiProducts",
];

export default function DatosEmpresariales() {
  return (
    <Grid sx={root} container spacing={4}>
      <Typography color="primary" variant="h4" align="left">
        Datos Empresariales
      </Typography>
      {inputValues.map((field, index) => (
        <EntradaEmpresarial key={index} field={field} />
      ))}
    </Grid>
  );
}

import React, { useContext, useState } from "react";
import {
  TextField,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  Grid,
  createTheme,
} from "@mui/material";

import ReactInputMask from "react-input-mask";
import { RegisterContext } from "../../utils/hooks/contexts/registerContext";
import {
  RegisterActions,
  RegisterStateIndexType,
} from "../../utils/hooks/contexts/registerReducer";
import { prop } from "../../utils/objectUtils";

const theme = createTheme();

const input = {
  width: "100%",
};
const radio = {
  padding: theme.spacing(0.5),
};

const inputValues: { [field: string]: { title: string; label: string } } = {
  name: {
    title:
      "Escriba el nombre de la empresa o persona registrada (o por registrar) en SICOP:",
    label: "Nombre de la empresa o persona",
  },
  id: {
    title:
      "Escriba el número de cédula del ente registrado (o por registrar) en SICOP:",
    label: "Cédula",
  },
  mainUser: {
    title: "Escriba su nombre completo:",
    label: "Nombre completo",
  },
  mainEmail: {
    title: "Escriba su correo",
    label: "Correo electronico",
  },
  province: {
    title: "Cantón en el que se ubica:",
    label: "Cantón",
  },
  canton: {
    title: "Provincia en la que se ubica:",
    label: "Provincia",
  },
  description: {
    title:
      'Haga una breve descripción del trabajo de su empresa: (Ej: "Somos una compañía de diseño y desarrollo web.")',
    label: "Escriba una breve descripción de su empresa",
  },
  products: {
    title:
      "Escriba una lista de productos que ofrece su empresa: (Ej: página web, software, interfaz de usuario, bases de datos, puntos de venta)",
    label: "Escriba algunos productos que ofrece su empresa",
  },
  antiProducts: {
    title:
      "Escriba una lista de productos que su empresa NO ofrece con los que podría ser confundidos: (Ej: suministros, venta de licencias, marketing digital)",
    label: "Escriba algunos productos que su empresa NO ofrece",
  },
};

export const EntradaEmpresarial = (props: { field: string }) => {
  const { state, dispatch } = useContext(RegisterContext);
  const [tipoCedula, setTipoCedula] = useState<string>("juridica");
  const [validezCorreo, setValidezCorreo] = useState<boolean>(true);

  const handleDataChange = (field: string, newValue: string) => {
    dispatch({
      type: RegisterActions.SET_BUSINESS_DATA,
      value: {
        ...state,
        [field]: newValue.replace(/ - /g, ""),
      },
    });
  };

  const cambiarTipoCedula = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTipoCedula((event.target as HTMLInputElement).value);
  };

  const validacionCorreo = (correo: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(correo.toLowerCase());
  };

  const handleEmailChange = (field: string, value: string) => {
    if (value === "" || validacionCorreo(value)) {
      setValidezCorreo(true);
    } else if (!validacionCorreo(value)) {
      setValidezCorreo(false);
    }
    dispatch({
      type: RegisterActions.SET_BUSINESS_DATA,
      value: {
        ...state,
        [field]: value,
      },
    });
  };

  if (props.field === "id") {
    let mask =
      tipoCedula === "juridica" ? "9 - 999 - 999999" : " 99 - 9999 - 9999";

    return (
      <Grid item xs={10} key={props.field}>
        <Typography color="primary" variant="h6" align="left">
          {inputValues[props.field].title}
        </Typography>
        <Grid item>
          <RadioGroup
            name="Tipo de identificación"
            value={tipoCedula}
            onChange={cambiarTipoCedula}
            row
          >
            <FormControlLabel
              value="juridica"
              control={<Radio sx={radio} />}
              label="Jurídica"
              labelPlacement="start"
            />
            <FormControlLabel
              value="personal"
              control={<Radio sx={radio} />}
              label="Personal"
              labelPlacement="start"
            />
          </RadioGroup>
          <ReactInputMask
            mask={mask}
            maskPlaceholder={null}
            value={state.id}
            onChange={(e) => handleDataChange(props.field, e.target.value)}
          >
            <TextField
              sx={input}
              label={inputValues[props.field].label}
              variant="outlined"
              required
            />
          </ReactInputMask>
        </Grid>
      </Grid>
    );
  } else if (props.field === "mainEmail") {
    return (
      <Grid item xs={10} key={props.field}>
        <Typography color="primary" variant="h6" align="left">
          {inputValues[props.field].title}
        </Typography>
        <TextField
          error={validezCorreo ? false : true}
          onChange={(e) => handleEmailChange(props.field, e.target.value)}
          sx={input}
          label={inputValues[props.field].label}
          variant="outlined"
          value={prop(state, props.field as RegisterStateIndexType)}
          helperText={
            validezCorreo ? null : "Escriba una dirección de correo válida"
          }
          required
        />
      </Grid>
    );
  } else {
    return (
      <Grid item xs={10} key={props.field}>
        <Typography color="primary" variant="h6" align="left">
          {inputValues[props.field].title}
        </Typography>
        <TextField
          multiline={
            ["description", "products"].includes(props.field) ? true : false
          }
          rows={["description", "products"].includes(props.field) ? 4 : 1}
          onChange={(e) => handleDataChange(props.field, e.target.value)}
          sx={input}
          label={inputValues[props.field].label}
          variant="outlined"
          value={prop(state, props.field as RegisterStateIndexType)}
          required={
            ["name", "mainUser", "description"].includes(props.field)
              ? true
              : false
          }
        />
      </Grid>
    );
  }
};

import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  createTheme,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControlLabel,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";

import licitanauta from "../../utils/astronauta_morado.png";

import firebase from "firebase/app";
import "firebase/auth";

import { useNavigate } from "react-router-dom";
import { useUserContext } from "../../utils/hooks/contexts/userContext";
import { useForm } from "../../utils/hooks/useForm";
import { useState } from "react";

export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" underline="none" href="https://licitanauta.com/">
        Licitanauta C.R.
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

interface FormData {
  email: string;
  password: string;
}

export const Login = () => {
  const navigate = useNavigate();
  const theme = createTheme();

  const { User, LoginUser } = useUserContext();
  const [campoVacio, setcampoVacio] = useState(false);
  const [correovalido, setCorreovalido] = useState(false);
  const [errorServidor, setErrorServidor] = useState(false);

  const { formulario, handleChange } = useForm<FormData>({
    email: "",
    password: "",
  });

  const { email, password } = formulario;

  async function loginUser() {
    const validaciones = [
      validarcampovacio(email),
      validarcampovacio(password),
      validarEmail(email),
    ];

    if (!validaciones.includes(false)) {
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(async (res) => {
          const resultado = res;

          const user = resultado.user;
          const token = (await user?.getIdToken()) ?? "";

          LoginUser({
            email: user?.email as string,
            username: user?.email as string,
            token: token as string,
          });
          localStorage.setItem("token", token);
          navigate("/dashboard");
        })
        .catch(() => setErrorServidor(true));
    }
  }

  function validarcampovacio(value: string): boolean {
    if (value.length === 0) {
      setcampoVacio(true);
      return false;
    }
    return true;
  }

  function validarEmail(email: string): boolean {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regex.test(email)) {
      setCorreovalido(true);
      return false;
    }
    return true;
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Container
        sx={{
          marginTop: theme.spacing(8),
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        disableGutters={true}
      >
        <Avatar sx={{ margin: theme.spacing(1), backgroundColor: "white" }}>
          <img
            data-testid="img-astro"
            src={licitanauta}
            height="40"
            width="40"
            alt="licitanauta"
          />
        </Avatar>
        <Typography component="h1" variant="h5" color="primary">
          Licitanauta
        </Typography>

        <Container
          sx={{
            width: "100%",
            marginTop: theme.spacing(1),
          }}
        >
          <TextField
            inputProps={{ "data-testid": "email-input" }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={handleChange}
          />
          <TextField
            inputProps={{ "data-testid": "password-input" }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={handleChange}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Recordarme"
            sx={{ display: "flex", marginLeft: 1 }}
          />
          <Button
            fullWidth
            data-testid="submit-button"
            variant="contained"
            color="primary"
            sx={{ margin: theme.spacing(2, 0, 0) }}
            onClick={async () => await loginUser()}
          >
            Entrar
          </Button>

          <Grid container sx={{ margin: theme.spacing(2, -2, 0) }}>
            <Grid item xs>
              <Link href="/passwordReset" underline="none" variant="body2">
                {"Olvidé mi contraseña"}
              </Link>
            </Grid>
            <Grid item xs style={{ textAlign: "end" }}>
              <Link href="/formularioRegistro" underline="none" variant="body2">
                {"Registrarse"}
              </Link>
            </Grid>
          </Grid>

          <Dialog open={campoVacio}>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Es necesario rellenar todos los campos obligatorios (*).
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setcampoVacio(false)}
                color="primary"
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={correovalido}>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                El email ingresado no es valido
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setCorreovalido(false)}
                color="primary"
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog open={errorServidor}>
            <DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Usuario o contraseña invalida
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => setErrorServidor(false)}
                color="primary"
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      </Container>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
};

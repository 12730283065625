import { createTheme } from "@mui/material";

const theme = createTheme();

export const root = {
  display: "flex",
  flexGrow: 1,
  justifyContent: "center",
};
export const content = {
  flexGrow: 1,
  height: "100%",
  overflow: "auto",
};
export const container = {
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
};
export const paper = {
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  width: "100%",
  backgroundColor: "fbfef9",
};
export const titulo = {
  fontFamily: `'Comfortaa', cursive`,
  margin: 4,
  zIndex: 10,
};
export const input = {
  width: "100%",
};
export const boton = {
  justifyContent: "space-around",
  display: "flex",
};
///////////////////////////////////////////////////////////////////////////////

export const typography = {
  padding: theme.spacing(2),
};
export const popover = {
  color: theme.palette.primary.main,
};

export const rootTEXTFIELD = {
  width: "100%",
  marginLeft: 10,
  "& .MuiInputBase-root.Mui-disabled": {
    color: "rgba(0, 0, 0, 0.8)", // (default alpha is 0.38)
  },
};

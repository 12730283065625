import { gql } from "@apollo/client";

export const MUTATION_ELIMINAR_LICITACION_DESTACADA = gql`
  mutation Mutation($emailUser: String, $numeroProcedimiento: String) {
    eliminarLicitacionGuardada(
      emailUser: $emailUser
      numeroProcedimiento: $numeroProcedimiento
    ) {
      mensaje
    }
  }
`;

import { gql } from "@apollo/client";

export const SEARCH_LICITACIONES = gql`
  query Query($pageSize: String, $pageIndex: String, $search: String) {
    licitaciones(pageSize: $pageSize, pageIndex: $pageIndex, search: $search) {
      pageSize
      pageIndex
      pageCount
      countItems
      items {
        numeroProcedimiento
        institucion
        descripcion
        presupuesto
        fechaCierreRecepcion
        garantiaParticipacion
        numeroCartel
      }
    }
  }
`;

import React, { useContext, useEffect, useState } from "react";
import UsuariosAdmin from ".//UsuariosAdmin";
import { useMutation, useQuery } from "@apollo/client";
import { SEND_USUARIOS_INFO } from "./Query/QueryUsuarios";
import { useUserContext } from "../../utils/hooks/contexts/userContext";
import UsuariosNoAdmin from ".//UsuariosNoAdmin";
import firebase from "firebase/app";
import "firebase/auth";
import { MUTATION_MODIFICAR_USUARIOS } from "./Query/MutationUsuarios";
import { QUERY_OBTENER_ROL_USUARIO } from "./Query/QueryRolUsuario";

export interface usuario {
  nombre: string;
  correo: string;
}

export interface usuarios {
  [key: number]: usuario;
}

const usuario_vacio = {
  nombre: "",
  correo: "",
};

const usuarios_vacios: usuarios = {
  1: usuario_vacio,
  2: usuario_vacio,
  3: usuario_vacio,
  4: usuario_vacio,
};

export const Usuarios = () => {
  const { User } = useUserContext();
  const [usuariosActuales, setUsuariosActuales] = useState(usuarios_vacios);
  const [refrescar, setRefrescar] = useState(false);
  const [rolUsuario, setRolUsuario] = useState(false);

  const QueryUsuarios = useQuery(SEND_USUARIOS_INFO, {
    variables: {
      emailUsuario: User.email,
    },
  });

  const QueryRolCliente = useQuery(QUERY_OBTENER_ROL_USUARIO, {
    variables: {
      emailUsuario: User.email,
    },
  });

  const [modificar] = useMutation(MUTATION_MODIFICAR_USUARIOS);

  let informacionUsuarios: usuario[] = [];
  function formatear(info: string) {
    const datos = info.split(",");
    informacionUsuarios.push({
      nombre: datos[1],
      correo: datos[0],
    });
  }

  useEffect(() => {
    if (QueryUsuarios.data) {
      const data = QueryUsuarios.data.obtenerInformacionUsuarios;

      const infoUsuarioPricipal = data.informacionUsuarioPrincipal ?? ",";
      formatear(infoUsuarioPricipal);

      let infoUsuario1 = data.informacionUsuario1 ?? ",";
      formatear(infoUsuario1);

      const infoUsuario2 = data.informacionUsuario2 ?? ",";
      formatear(infoUsuario2);

      const infoUsuario3 = data.informacionUsuario3 ?? ",";
      formatear(infoUsuario3);

      let usuarios_cargados = usuarios_vacios;
      informacionUsuarios.forEach((res, index) => {
        usuarios_cargados[index + 1] = {
          nombre: res.nombre.toString(),
          correo: res.correo.toString(),
        };
      });
      setUsuariosActuales(usuarios_cargados);

      setRefrescar(!refrescar);
      QueryUsuarios.refetch();
    }
  }, [QueryUsuarios.data]);

  const modificarUsuarios = async (
    emailsNuevos: string[],
    infoEnviar: string[]
  ) => {
    emailsNuevos.forEach(async (res) => {
      const info = res.split(",");
      const email = info[0];
      const password = info[1];

      try {
        await firebase.auth().createUserWithEmailAndPassword(email, password);
      } catch (err) {}
    });

    await modificar({
      variables: {
        emailUsuario: User.email,
        informacionUsuarioPrincipal: infoEnviar[0],
        informacionUsuario1: infoEnviar[1],
        informacionUsuario2: infoEnviar[2],
        informacionUsuario3: infoEnviar[3],
      },
    });

    QueryUsuarios.refetch();
    setRefrescar(!refrescar);
  };

  useEffect(() => {
    if (QueryRolCliente.data) {
      const rol = QueryRolCliente.data.obternerRolUsuario.mensaje ?? "";
      if (rol === "EsUsuarioAdministrador") setRolUsuario(true);
      if (rol === "EsUsuarioNormal") setRolUsuario(false);
    }
  }, [QueryRolCliente.data]);

  if (rolUsuario) {
    return (
      <UsuariosAdmin
        modificarUsuarios={modificarUsuarios}
        refrescar={refrescar}
        usuariosActuales={usuariosActuales}
      />
    );
  } else {
    return (
      <UsuariosNoAdmin
        refrescar={refrescar}
        usuariosActuales={usuariosActuales}
      />
    );
  }
};

import { gql } from "@apollo/client";

export const SEND_CALENDARIO_INFO_QUERY = gql`
  query Query($fecha: String, $emailUsuario: String) {
    calendarioInfo(fecha: $fecha, emailUsuario: $emailUsuario) {
      numeroProcedimiento
      descripcion
    }
  }
`;

import { gql } from "@apollo/client";

export const MUTATION_UPDATE_CLIENTE = gql`
  mutation Mutation(
    $nombre: String
    $emailUser: String
    $provincia: String
    $canton: String
    $descripcion: String
    $productos: String
    $antiProductos: String
    $keywords: String
  ) {
    updateCliente(
      nombre: $nombre
      emailUser: $emailUser
      canton: $canton
      provincia: $provincia
      descripcion: $descripcion
      productos: $productos
      antiProductos: $antiProductos
      keywords: $keywords
    ) {
      nombre
      provincia
      canton
    }
  }
`;

export const QUERY_GETINFO_CLIENTE = gql`
  query Query($emailUser: String) {
    cliente(emailUser: $emailUser) {
      nombre
      cedula
      keywords
      provincia
      productos
      antiProductos
      descripcion
      canton
      fechaRegistro
    }
  }
`;

export const QUERY_OBTENER_ROL_USUARIO = gql`
  query Query($emailUsuario: String) {
    obternerRolUsuario(emailUsuario: $emailUsuario) {
      mensaje
    }
  }
`;

import React, { useReducer } from "react";
import {
  RegisterState,
  RegisterActions,
  registerReducer,
} from "./registerReducer";

interface Props {
  children: JSX.Element | JSX.Element[];
}

const initialRegisterState: RegisterState = {
  name: "",
  id: "",
  mainUser: "",
  mainEmail: "",
  province: "",
  canton: "",
  description: "",
  products: "",
  antiProducts: "",
  users: [
    {
      name: "",
      email: "",
    },
    {
      name: "",
      email: "",
    },
    {
      name: "",
      email: "",
    },
  ],
};

const RegisterContext = React.createContext<{
  state: RegisterState;
  dispatch: React.Dispatch<{ type: RegisterActions; value: RegisterState }>;
}>({
  state: initialRegisterState,
  dispatch: () => null,
});

const RegisterContextProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(registerReducer, initialRegisterState);

  return (
    <RegisterContext.Provider value={{ state, dispatch }}>
      {children}
    </RegisterContext.Provider>
  );
};

export { RegisterContextProvider, RegisterContext };

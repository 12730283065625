import { createTheme } from "@mui/material";

const theme = createTheme();

export const root = {
  display: "flex",
  alignItems: "center",
  width: "100%",
  height: "100%",
};
export const input = {
  marginLeft: theme.spacing(1),
  flex: 1,
};
export const iconButton = {
  paddingLeft: 2,
};
export const divider = {
  height: 28,
  margin: 1.5,
};

import "./App.css";

import { Container, createTheme, ThemeProvider } from "@mui/material";
import { UserProvider } from "./utils/hooks/contexts/userContext";
import Routing from "./utils/routes/Routing";
import firebase from "firebase/app";
import firebaseConfig from "./utils/firebaseConfig";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import {
  authMiddleware,
  errorMiddleware,
} from "./utils/middlewares/middlewares";

const theme = createTheme({
  palette: {
    primary: { main: "#340046" },
    secondary: { main: "#0e79b2" },
    warning: { main: "#fcba03" },
  },
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const httpLink = new HttpLink({ uri: process.env.REACT_APP_BACKEND_API });
const link = ApolloLink.from([
  errorMiddleware,
  authMiddleware.concat(httpLink),
]);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache(),

  defaultOptions: defaultOptions,
});

function App() {
  firebase.initializeApp(firebaseConfig);
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <ApolloProvider client={client}>
          <Routing />
        </ApolloProvider>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;

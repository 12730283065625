import { createTheme } from "@mui/material";

const theme = createTheme();

export const root = {
  display: "flex",
  width: "100%",
};
export const content = {
  flexGrow: 1,
  height: "100%",
  overflow: "auto",
};
export const container = {
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
};
export const paper = {
  margin: theme.spacing(3),
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  width: "100%",
};
export const fixedHeight = {
  height: 260,
};
export const instruccion = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
};

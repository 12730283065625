import React from "react";
import { Button } from "@mui/material";

const meses = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "setiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

export const Mes = (props: {
  setVista: (vista: string) => void;
  mes: string;
  setMes: (mes: number) => void;
}) => {
  const cambiarMes = () => {
    props.setMes(meses.indexOf(props.mes));
    props.setVista("dias");
  };

  return (
    <Button color="primary" size="medium" onClick={cambiarMes}>
      {props.mes}
    </Button>
  );
};

import { useQuery } from "@apollo/client";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { useUserContext } from "../../utils/hooks/contexts/userContext";
import { useForm } from "../../utils/hooks/useForm";
import { SEND_SOPORTE_QUERY } from "./Query/QuerySoporte";
import * as Styles from "./Styles";

interface FormData {
  asunto: string;
  contenido: string;
}

export const Soporte = () => {
  const [enviado, setEnviado] = useState(false);
  const [estado, setEstado] = useState(false);
  const [sendQuery, setSendQuery] = useState(true);
  const { User } = useUserContext();

  const { formulario, handleChange } = useForm<FormData>({
    asunto: "",
    contenido: "",
  });

  const { asunto, contenido } = formulario;

  const { loading, data, refetch } = useQuery(SEND_SOPORTE_QUERY, {
    variables: {
      correo: User.email,
      asunto: asunto,
      contenido: contenido,
    },
    skip: sendQuery,
  });

  useEffect(() => {
    if (data) {
      const mensaje = data.enviarSoporte.mensaje;

      if (mensaje === "Exito") {
        setEstado(true);
        setEnviado(true);
      } else if (mensaje === "Fallo") {
        setEstado(false);
        setEnviado(true);
      }
    }

    setSendQuery(true);
  }, [data]);

  const enviarConsulta = () => {
    setSendQuery(false);
    refetch();
  };

  const ConfirmarEnvio = () => {
    return (
      <Dialog open={enviado}>
        <DialogTitle>
          <Typography
            color="primary"
            style={{ fontFamily: `"Comfortaa", cursive` }}
          >
            {estado ? "Consulta enviada" : "Consulta fallida"}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {estado
              ? "Su consulta ha sido enviada, le responderemos a su correo lo antes posible."
              : "Su consulta no pudo ser enviada por favor intentalo mas tarde"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setEnviado(false)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Container maxWidth="lg" sx={Styles.container}>
      <Paper sx={Styles.paper}>
        <Grid sx={Styles.root} container spacing={3}>
          <Grid item xs={12}>
            <Typography
              sx={Styles.titulo}
              color="primary"
              variant="h4"
              align="left"
            >
              Consultar Soporte
            </Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography color="primary" variant="h6">
              Asunto
            </Typography>
            <TextField
              onChange={handleChange}
              name="asunto"
              sx={Styles.input}
              variant="outlined"
              placeholder="Asunto"
            />
          </Grid>
          <Grid item xs={11}>
            <Typography color="primary" variant="h6">
              Consulta
            </Typography>
            <TextField
              multiline={true}
              rows={10}
              onChange={handleChange}
              name="contenido"
              sx={Styles.input}
              variant="outlined"
              placeholder="Describa su consulta los más detalladamente posible."
            />
          </Grid>
          <Grid item sm={12} sx={Styles.boton}>
            <Button
              size="large"
              color="primary"
              variant="contained"
              onClick={enviarConsulta}
            >
              Enviar
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <ConfirmarEnvio />

      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
};

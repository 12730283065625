import React, { useState, useEffect, useContext } from "react";

import { usuario, usuarios } from "./Usuarios";

import * as firebase from "firebase/app";
import "firebase/auth";
import * as Styles from "./Styles";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";

const user: usuario = {
  nombre: "",
  correo: "",
};

const users: usuarios = {
  1: user,
  2: user,
  3: user,
  4: user,
};

interface PropsUsuariosAdmin {
  usuariosActuales: usuarios;
  refrescar: boolean;
  modificarUsuarios: (emailsNuevos: string[], emailsEnviar: string[]) => {};
}

function password() {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < 10; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function validarCorreos(emails: usuarios) {
  const regex =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  let validos = true;
  [1, 2, 3, 4].forEach((number) => {
    const email = emails[number].correo;
    if (!regex.test(email) && email.length > 0) {
      validos = false;
    }
  });

  return validos;
}

export default function UsuariosAdmin(props: PropsUsuariosAdmin) {
  const [confirmacion, setConfirmacion] = useState(false);
  const [correoValido, setCorreoValido] = useState(false);
  const [usuariosModificados, setUsuariosModificados] = useState(users);
  useEffect(() => {
    setUsuariosModificados(props.usuariosActuales);
  }, [props.usuariosActuales, props.refrescar]);

  const handleChange = (
    event: React.ChangeEvent<{ value: unknown }>,
    usuario: number,
    set: Function,
    nombreOcorreo: string
  ) => {
    setUsuariosModificados({
      ...usuariosModificados,
      [usuario]: {
        ...usuariosModificados[usuario],
        [nombreOcorreo]: event.target.value,
      },
    });
  };

  const ConfirmarCambios = () => {
    return (
      <Dialog open={confirmacion}>
        <DialogTitle>
          <Typography
            variant="h6"
            style={{ fontFamily: `"Comfortaa", cursive` }}
          >
            Confirmación de cambios
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography variant="body1">
              Recuerde que al realizar estos cambios, los usuarios eliminados
              dejarán de recibir correos diarios y perderán acceso a la
              plataforma.
            </Typography>
            <br />
            <Typography variant="body1">
              Los nuevos usuarios recibirán correos diarios y un correo con la
              contraseña para ingresar a la plataforma en línea.
            </Typography>
            <br />
            <Typography variant="body1">
              ¿Desea continuar con los cambios?
            </Typography>
            <br />
            <Typography variant="body1">
              (Una vez guardados los cambios no podrá revertirlos.)
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setConfirmacion(false)}
            color="primary"
          >
            No
          </Button>
          <Button variant="contained" onClick={guardarCambios} color="primary">
            Sí
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const guardarCambios = async () => {
    setConfirmacion(false);

    const validos = validarCorreos(usuariosModificados);
    if (!validos) {
      setCorreoValido(true);
      return;
    }

    let emailsActuales: string[] = [];
    let emailsModificados: string[] = [];
    [1, 2, 3, 4].forEach((i) => {
      emailsActuales.push(props.usuariosActuales[i].correo);

      emailsModificados.push(usuariosModificados[i].correo);
    });

    let emailsDiferentes = emailsModificados.filter(
      (email) => !emailsActuales.includes(email) && email !== ""
    );

    const infoUsarios = emailsModificados.map((email, index) => {
      const PASSWORD = password();

      let rolUsuario = "0";
      if (index == 0) rolUsuario = "1";
      const nombre = usuariosModificados[index + 1].nombre;

      emailsDiferentes.forEach((res, i) => {
        if (res === email) emailsDiferentes[i] = `${res},${PASSWORD}`;
      });

      const informacion = `${email},${nombre},${PASSWORD},${rolUsuario}`;

      return email !== "" ? informacion : "";
    });

    props.modificarUsuarios(emailsDiferentes, infoUsarios);
  };

  return (
    <Container maxWidth="lg" sx={Styles.container}>
      <Paper sx={Styles.paper}>
        <Typography sx={Styles.titulo} variant="h4">
          Usuarios registrados
        </Typography>
        <Grid container spacing={2}>
          {[1, 2, 3, 4].map((numero) => (
            <Grid
              container
              item
              xs={12}
              spacing={1}
              sx={Styles.usuario}
              key={`usuario${numero}`}
            >
              <Grid item sm={5}>
                <Typography sx={Styles.etiqueta}>
                  Nombre{numero === 1 ? " Administrador" : ""}
                </Typography>
              </Grid>
              <Grid item sm={7}>
                <Typography sx={Styles.etiqueta}>
                  Correo{numero === 1 ? " Administrador" : ""}
                </Typography>
              </Grid>
              <Grid item sm={5}>
                <TextField
                  onChange={(e) =>
                    handleChange(e, numero, setUsuariosModificados, "nombre")
                  }
                  value={usuariosModificados[numero].nombre}
                  sx={Styles.nombre}
                  variant="outlined"
                />
              </Grid>
              <Grid item sm={7}>
                <TextField
                  onChange={(e) =>
                    handleChange(e, numero, setUsuariosModificados, "correo")
                  }
                  value={usuariosModificados[numero].correo}
                  sx={Styles.correo}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          ))}
          <Grid item sm={12} sx={Styles.boton}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setUsuariosModificados(props.usuariosActuales)}
            >
              Revertir cambios
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setConfirmacion(true)}
            >
              Guardar cambios
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <ConfirmarCambios />

      <Dialog open={correoValido}>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Los emails deben ser validos
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => setCorreoValido(false)}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

import React from "react";
import { Grid } from "@mui/material";

import { Mes } from "./Mes";

const content = {
  flexGrow: 1,
  width: "100%",
  overflow: "auto",
  textAlign: "center",
  height: "10rem",
};
const boton = {
  borderRadius: 20,
};

const meses = [
  "enero",
  "febrero",
  "marzo",
  "abril",
  "mayo",
  "junio",
  "julio",
  "agosto",
  "setiembre",
  "octubre",
  "noviembre",
  "diciembre",
];

export const VistaMeses = (props: {
  setVista: (vista: string) => void;
  setMes: (mes: number) => void;
}) => {
  return (
    <Grid item container sx={content}>
      {meses.map((mes) => {
        return (
          <Grid sx={boton} xs={4}>
            <Mes setVista={props.setVista} mes={mes} setMes={props.setMes} />
          </Grid>
        );
      })}
    </Grid>
  );
};

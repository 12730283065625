import { createTheme } from "@mui/material";

const theme = createTheme();

export const contenido = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-start",
  overflow: "hidden",
};
export const backdrop = {
  zIndex: theme.zIndex.drawer + 1,
  color: theme.palette.primary.main,
};

export type RegisterUserIndexType = "name" | "email"

export interface RegistrationUser {
    name: string
    email: string
}

export type RegisterStateIndexType = "id" | "name" | "mainUser" | "mainEmail" | "province" | "canton" | "description" | "products" | "antiProducts" | "users"

export interface RegisterState {
    name: string
    id: string
    mainUser: string,
    mainEmail: string,
    province: string
    canton: string
    description: string
    products: string
    antiProducts: string
    users: RegistrationUser[]
}

export enum RegisterActions {
    REFRESH,
    SET_BUSINESS_DATA,
    SET_REGISTRATION_USERS
}

export type Action = | {type: RegisterActions, value: RegisterState}

export const registerReducer = (state: RegisterState, action: Action) => {
    const actionsReducer = {
        [RegisterActions.REFRESH]: () => ({
            ...action.value
        }),
        [RegisterActions.SET_BUSINESS_DATA]: () => ({
            ...state,
            name: action.value.name,
            id: action.value.id,
            mainUser: action.value.mainUser,
            mainEmail: action.value.mainEmail,
            province: action.value.province,
            canton: action.value.canton,
            description: action.value.description,
            products: action.value.products,
            antiProducts: action.value.antiProducts,
        }),
        [RegisterActions.SET_REGISTRATION_USERS]: () => ({
            ...state,
            users: action.value.users
        }),
    }
    return actionsReducer[action.type]()
}
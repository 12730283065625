import { createTheme } from "@mui/material";

const theme = createTheme();

export const header = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#340046",
  borderCollapse: "collapse",
  zIndex: theme.zIndex.drawer + 1,
};

export const toolBar = {
  width: "inherit",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};
export const outlined = {
  padding: "10px",
  color: "#ffffff !important",
  borderColor: "#ffffff",
};
export const underline = {
  "&:before": {
    height: "2px",
    backgroundColor: theme.palette.secondary.main,
  },
  "&:hover:not": {
    borderBottom: "2px solid #fffff",
  },
};
export const icon = {
  fill: "#ffffff",
};
export const item = {
  color: theme.palette.primary.main,
  fontWeight: 500,
};
export const banner = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  verticalAlign: "center",
  height: "3rem",
};
export const astronauta = {
  marginTop: 2,
  width: "2.5rem",
  height: "2.5rem",
};
export const titulo = {
  fontWeight: 700,
  paddingLeft: "0.625rem",
  paddingTop: "0.625rem",
  height: "4.375rem",
  fontFamily: `'Comfortaa', cursive`,
  color: "#ffffff",
};
export const primary = {
  color: "#fa6320",
  fontSize: 30,
};
export const signOut = {
  margin: "auto 20px",
};

import {
  Button,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import { Licitacion } from "../../../models/Licitacion";

import * as Styles from "./ResultadosStyles";

interface PropsDestacadas {
  licitaciones: Licitacion[];
  destacar: (numeroProcedimiento: string) => void;
}

function link(numeroCartel: string) {
  return `https://www.sicop.go.cr/moduloPcont/pcont/ctract/es/CE_CEJ_ESQ002.jsp?cartelNo=${numeroCartel}&cartelSeq=00`;
}

export default function Resultados(props: PropsDestacadas) {
  return (
    <React.Fragment>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={`${Styles.tableHeader} ${Styles.cell}`}>
              Numero Procedimiento
            </TableCell>
            <TableCell className={`${Styles.tableHeader} ${Styles.cell}`}>
              Institución
            </TableCell>
            <TableCell className={`${Styles.tableHeader} ${Styles.cell}`}>
              Descripción
            </TableCell>
            <TableCell className={`${Styles.tableHeader} ${Styles.cell}`}>
              Presupuesto
            </TableCell>
            <TableCell className={`${Styles.tableHeader} ${Styles.cell}`}>
              Fecha Cierre
            </TableCell>
            <TableCell className={`${Styles.tableHeader} ${Styles.cell}`}>
              Garantía Participación
            </TableCell>
            <TableCell
              className={`${Styles.tableHeader} ${Styles.cell}`}
            ></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.licitaciones.map((licitacion: Licitacion) => (
            <TableRow key={licitacion.numeroCartel} hover={true}>
              <TableCell sx={Styles.cell}>
                <Link
                  target="_blank"
                  href={link(licitacion.numeroCartel)}
                  color="secondary"
                >
                  {licitacion.numeroProcedimiento}
                </Link>
              </TableCell>
              <TableCell sx={Styles.cell}>{licitacion.institucion}</TableCell>
              <TableCell sx={Styles.cell}>{licitacion.descripcion}</TableCell>
              <TableCell sx={Styles.cell}>{licitacion.presupuesto}</TableCell>
              <TableCell sx={Styles.cell}>
                {licitacion.fechaCierreRecepcion}
              </TableCell>
              <TableCell sx={Styles.cell}>
                {licitacion.garantiaParticipacion}
              </TableCell>
              <TableCell sx={Styles.cell}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => props.destacar(licitacion.numeroProcedimiento)}
                >
                  Destacar
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

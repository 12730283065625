import React from "react";
import {
  Popover,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  Box,
} from "@mui/material";

import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { licitacion } from "../Tablero";
import { createTheme } from "@mui/material";

const theme = createTheme();

const item = {
  maxWidth: "40rem",
};
const texto = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
};
const typography = {
  padding: theme.spacing(1),
};

interface licitacionesCierreInfo {
  numeroProcedimiento: string;
  descripcion: string;
}

export const InfoDia = (props: {
  licitaciones: licitacionesCierreInfo[];
  open: boolean;
  anchorEl: HTMLButtonElement | null;
  onClose: () => void;
}) => {
  function Texto() {
    return (
      <List dense={true}>
        {props.licitaciones.map((licitacion, index, array) => {
          return (
            <Box key={index}>
              <ListItem sx={item} key={index}>
                <ListItemIcon>
                  <FiberManualRecordIcon />
                </ListItemIcon>
                <Box sx={texto}>
                  <Typography>{licitacion.numeroProcedimiento}</Typography>
                  <Typography variant="body2" align="center" sx={typography}>
                    {licitacion.descripcion}
                  </Typography>
                </Box>
              </ListItem>
              {index < array.length - 1 ? <Divider /> : null}
            </Box>
          );
        })}
      </List>
    );
  }

  return (
    <Popover
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Texto />
    </Popover>
  );
};

import { createTheme } from "@mui/material";

const theme = createTheme();

export const root = {
  display: "flex",
  flexGrow: 1,
  justifyContent: "center",
};
export const content = {
  flexGrow: 1,
  height: "100%",
};
export const container = {
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
};
export const paper = {
  padding: theme.spacing(3),
  display: "flex",
  flexDirection: "column",
  width: "80%",
  backgroundColor: "fbfef9",
};
export const titulo = {
  fontFamily: `'Comfortaa', cursive`,
};
export const input = {
  width: "100%",
  marginLeft: 1,
};
export const boton = {
  justifyContent: "space-around",
  display: "flex",
  width: "3rem",
};

import React from "react";
import { Typography } from "@mui/material";

export const TituloDia = (props: { dia: String }) => {
  return (
    <Typography variant="subtitle2" color="secondary">
      {props.dia}
    </Typography>
  );
};

import { gql } from "@apollo/client";

export const GET_LICITACIONES_QUERY = gql`
  query Query($pageSize: String, $pageIndex: String, $emailUsuario: String) {
    licitaciones(
      pageSize: $pageSize
      pageIndex: $pageIndex
      emailUsuario: $emailUsuario
    ) {
      pageSize
      pageIndex
      pageCount
      countItems
      items {
        numeroProcedimiento
        institucion
        descripcion
        presupuesto
        fechaCierreRecepcion
        garantiaParticipacion
        estado
      }
    }
  }
`;
